import { Chapter, EventStatus } from '@bellepoque/api-contracts';
import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import { UpdateReplayRequest } from '../../../../../core/usecases/event/crud/update-replay';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import ReplayLinksHeader from '../../../molecules/event/replay-links/ReplayLinksHeader';
import UpdateReplayCoverDialog from '../UpdateReplayCoverDialog';
import UpdateReplayInfosFormDialog from '../UpdateReplayInfosFormDialog';
import { createReplayLinksViewModel } from './ReplayLinks.viewmodel';
import ReplayLinksDownloads from './ReplayLinksDownloads';
import ReplayLinksProductsChapters from './ReplayLinksProductsChapters';

export type UpdateReplayCoverPayload = Pick<UpdateReplayRequest['payload'], 'coverUploaded'>;
export type UpdateReplayInfosPayload = Omit<UpdateReplayRequest['payload'], 'coverUploaded'>;

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  textAlign: 'initial',
});

interface ReplayLinksProps {
  event: CBOEventReadModel;
}

export type CBOEventReadModelProductWithTimeCode = {
  product: CBOEventReadModelProduct;
  timeCode: number;
};

const getProductsWithTimeCodes = (chapters: Chapter[], eventProducts: CBOEventReadModelProduct[]) => {
  return chapters.reduce((acc: CBOEventReadModelProductWithTimeCode[], chapter: Chapter) => {
    const product = eventProducts.find(({ id }) => chapter.productId === id);
    const isAlreadyAdded = !!acc.find(({ product }) => product.id === chapter.productId);

    if (product && !isAlreadyAdded) {
      acc.push({
        product,
        timeCode: Math.round(chapter.durationFromStart / 1000),
      });
    }

    return acc;
  }, []);
};

export default function ReplayLinks({ event }: ReplayLinksProps) {
  const viewModel = useSelector(createReplayLinksViewModel({ dispatch: useDispatch() }));
  const {
    currentTenantId,
    currentTenantName,
    eventRealtimeData,
    fetchFileUploadUrl,
    fetchLandingPageData,
    fetchUploadUrlStatus,
    hasCmsLandingPage,
    landingPageUrl,
    trackUserJourneyEvent,
    updateReplay,
    updateReplayStatus,
    resetUpdateReplay,
    uploadFile,
    uploadFileStatus,
  } = viewModel;

  const [productsWithTimeCode, setProductsWithTimeCode] = useState<CBOEventReadModelProductWithTimeCode[]>([]);
  const [isEditImageModalShown, setIsUpdateImageModalShown] = useState<boolean>(false);
  const [isEditEventInfoModalShown, setIsUpdateInfoModalShown] = useState<boolean>(false);
  const [selectedReplayCover, setSelectedReplayCover] = useState<File | null>(null);

  const handleCopyUrl = (url: string) => navigator.clipboard.writeText(url);

  useEffect(() => {
    trackUserJourneyEvent({
      data: {
        ...eventToUserJourneyData(event),
        'Tenant id': currentTenantId,
        'Tenant name': currentTenantName,
      },
      name: 'Replay links',
    });
  }, []);

  useEffect(() => {
    if (hasCmsLandingPage) {
      fetchLandingPageData();
    }
  }, [hasCmsLandingPage]);

  useEffect(() => {
    if (
      (eventRealtimeData?.status === EventStatus.REPLAY ||
        eventRealtimeData?.status === EventStatus.PRIVATE_REPLAY ||
        eventRealtimeData?.status === EventStatus.REPLAY_READY) &&
      event.products.length > 0
    ) {
      const productsWithTimeCodes = getProductsWithTimeCodes(eventRealtimeData.chapters, event.products);
      setProductsWithTimeCode(productsWithTimeCodes);
    }
  }, [eventRealtimeData, event.products]);

  useEffect(() => {
    const canUploadFile = selectedReplayCover && fetchUploadUrlStatus === 'loaded';
    if (canUploadFile) {
      uploadFile(selectedReplayCover);
    }
  }, [fetchUploadUrlStatus, selectedReplayCover]);

  useEffect(() => {
    if (uploadFileStatus === 'success') {
      updateReplay({ coverUploaded: true, description: event.description, title: event.title });
    }
  }, [uploadFileStatus]);

  useEffect(() => {
    if (updateReplayStatus === 'success') {
      setIsUpdateImageModalShown(false);
      setIsUpdateInfoModalShown(false);
      resetUpdateReplay();
    }
  }, [updateReplayStatus]);

  const handleSubmitUpdateReplayCover = (file: File) => {
    setSelectedReplayCover(file);
    fetchFileUploadUrl();
  };

  const handleSubmitUpdateReplayInfos = (payload: UpdateReplayInfosPayload) => {
    updateReplay({
      ...payload,
      coverUploaded: false,
    });
  };

  return (
    <Container>
      <ReplayLinksHeader
        event={event}
        onShowUpdateImageModal={() => setIsUpdateImageModalShown(true)}
        onShowUpdateInfoModal={() => setIsUpdateInfoModalShown(true)}
      />
      <ReplayLinksDownloads
        areLinksShown={!hasCmsLandingPage}
        arePlayButtonsShown={hasCmsLandingPage}
        eventId={event.id}
        landingPageUrl={hasCmsLandingPage ? landingPageUrl : null}
        onCopyUrl={handleCopyUrl}
        replayFileUrl={event.replayFileUrl}
      />
      {productsWithTimeCode.length > 0 && (
        <ReplayLinksProductsChapters
          areLinksShown={!hasCmsLandingPage}
          arePlayButtonsShown={hasCmsLandingPage}
          chapters={productsWithTimeCode}
          eventId={event.id}
          landingPageUrl={hasCmsLandingPage ? landingPageUrl : null}
          onCopyUrl={handleCopyUrl}
        />
      )}
      <UpdateReplayCoverDialog
        isOpen={isEditImageModalShown}
        onClose={() => setIsUpdateImageModalShown(false)}
        onSubmit={handleSubmitUpdateReplayCover}
        submissionInProgress={
          updateReplayStatus === 'pending' || fetchUploadUrlStatus === 'pending' || uploadFileStatus === 'pending'
        }
      />
      <UpdateReplayInfosFormDialog
        event={event}
        onClose={() => setIsUpdateInfoModalShown(false)}
        onSubmit={handleSubmitUpdateReplayInfos}
        open={isEditEventInfoModalShown}
        submissionInProgress={updateReplayStatus === 'pending'}
      />
    </Container>
  );
}
