import { V1 } from '@bellepoque/api-contracts';
import { v4 as uuidv4 } from 'uuid';

export type CBOEventReadModelProductVariant = V1.readModels.CatalogProductVariantReadModelDTO;

export type CBOEventReadModelProductOption = V1.readModels.CatalogProductOptionReadModelDTO;

export type CBOEventReadModelProductOptions = V1.readModels.CatalogProductOptionsReadModelDTO & {
  samePricesChecked: boolean;
};

export function getEmptyCBOProductOptions(): CBOEventReadModelProductOptions {
  return {
    option1: getEmptyCBOProductOption(),
    option2: null,
    option3: null,
    samePricesChecked: false,
    variants: [],
    variantsHaveSamePrice: false,
  };
}

export function getEmptyCBOProductOption(): CBOEventReadModelProductOption {
  return {
    allValues: [],
    id: uuidv4(),
    name: '',
    selectedValues: [],
    type: '',
  };
}

export function toCBOProductOptions(
  optionsDTO: V1.readModels.CatalogProductOptionsReadModelDTO,
): CBOEventReadModelProductOptions {
  return {
    ...optionsDTO,
    samePricesChecked: optionsDTO.variantsHaveSamePrice,
  };
}
