import { WarningRounded } from '@mui/icons-material';
import { Box, Tab, TabProps, styled, useTheme } from '@mui/material';
import React from 'react';

import ReleaseBadge, { ReleaseBadgeProps } from './ReleaseBadge';

type StyledTabProps = Pick<TabProps, 'aria-controls' | 'icon' | 'id' | 'label' | 'LinkComponent'> & {
  hasWarning?: boolean;
  releaseStatus?: ReleaseBadgeProps['status'];
};

const IconContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0),
  top: theme.spacing(-1),
}));

export default function StyledTab(props: StyledTabProps) {
  const theme = useTheme();

  const renderInfoIcon = () => {
    if (props.hasWarning) {
      return (
        <IconContainer>
          <WarningRounded color="error" sx={{ fontSize: '1.3em' }} />
        </IconContainer>
      );
    }

    if (props.releaseStatus) {
      return (
        <IconContainer>
          <ReleaseBadge size="small" status={props.releaseStatus} />
        </IconContainer>
      );
    }

    return null;
  };
  return (
    <Box position="relative">
      <Tab
        {...props}
        sx={{
          '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
          },
          '&.Mui-selected': {
            color: theme.palette.primary.dark,
            fontWeight: 'bolder',
            opacity: 1,
          },
          color: theme.palette.text.primary,
          fontWeight: 'bold',
          opacity: 0.5,
          textTransform: 'initial',
        }}
      />
      {renderInfoIcon()}
    </Box>
  );
}
