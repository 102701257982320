import { BILLING_PLANS, BillingPlan, V1 } from '@bellepoque/api-contracts';
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { State } from '../../../../core/store';

type Inputs = V1.api.GenerateActivationLinkRequest;

interface EventFinderFormProps {
  loading: boolean;
  onSubmit: (values: Inputs) => void;
}

export default function GenerateActivationLinkForm({ loading, onSubmit }: EventFinderFormProps) {
  const { t } = useTranslation(['administration', 'common']);

  const tenants = useSelector((state: State) => state.tenants).tenants.filter((t) => t.cms === 'SHOPIFY');

  const plans = useMemo(() => (Object.values(BILLING_PLANS) as BillingPlan[]).filter((p) => p.monthlyPrice > 0), []);

  const defaultValues: Inputs = {
    disableBilling: undefined,
    discount: undefined,
    period: undefined,
    plan: plans[0].id,
    price: undefined,
    tenantName: tenants[0].name,
    trial: undefined,
  };

  const defaultTenant = useMemo(() => tenants.find((t) => t.name === defaultValues.tenantName), [tenants]);
  const defaultPlan = useMemo(() => plans.find((p) => p.id === defaultValues.plan), [plans]);

  const { register, handleSubmit, formState, setValue } = useForm<Inputs>({
    defaultValues,
    resolver: zodResolver(V1.api.GenerateActivationLinkRequestSchema),
  });

  const errors = formState.errors;

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
      }}
    >
      <Box
        mb={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Autocomplete
          defaultValue={defaultTenant}
          disableClearable
          fullWidth
          getOptionLabel={(tenant) => tenant.name}
          handleHomeEndKeys
          onChange={(e, newTenant) => {
            setValue('tenantName', newTenant?.name);
          }}
          options={tenants}
          renderInput={(params) => <TextField {...params} label={t('TenantName')} variant="outlined" />}
          renderOption={(props, tenant) => <li {...props}>{tenant.name}</li>}
          selectOnFocus
        />
        <Autocomplete
          defaultValue={defaultPlan}
          disableClearable
          fullWidth
          getOptionLabel={(plan) => `${plan.name} (${plan.id}) - ${plan.monthlyPrice}$`}
          handleHomeEndKeys
          onChange={(e, newPlan) => setValue('plan', newPlan?.id)}
          options={plans}
          renderInput={(params) => <TextField {...params} label={t('Plan')} variant="outlined" />}
          renderOption={(props, plan) => (
            <li {...props}>
              {plan.name} ({plan.id}) - {plan.monthlyPrice}$
            </li>
          )}
        />
        <TextField
          fullWidth
          {...register('price', {
            setValueAs: (i) => (i === '' ? undefined : Number.isNaN(Number(i)) ? i : Number(i)),
          })}
          error={!!errors.price}
          helperText={errors.price?.message}
          label={t('Price')}
          variant="outlined"
        />
        <TextField
          fullWidth
          {...register('discount', {
            setValueAs: (i) => (i === '' ? undefined : Number.isNaN(Number(i)) ? i : Number(i)),
          })}
          error={!!errors.discount}
          helperText={errors.discount?.message}
          label={t('Discount')}
          variant="outlined"
        />
        <Autocomplete
          fullWidth
          getOptionLabel={({ label }) => t(label)}
          handleHomeEndKeys
          onChange={(e, newPeriod) => setValue('period', newPeriod?.id)}
          options={[
            { id: 'YEARLY' as const, label: 'Yearly' },
            { id: '30_DAYS' as const, label: 'Every30Days' },
          ]}
          renderInput={(params) => <TextField {...params} label={t('BillingPeriod')} variant="outlined" />}
        />
        <TextField
          fullWidth
          {...register('trial', {
            setValueAs: (i) => (i === '' ? undefined : Number.isNaN(Number(i)) ? i : Number(i)),
          })}
          error={!!errors.trial}
          helperText={errors.trial?.message}
          label={t('TrialDuration')}
          variant="outlined"
        />
        <FormControlLabel
          control={<Checkbox {...register('disableBilling')} />}
          label={t('DisableRealBilling') as string}
        />
      </Box>
      <Button color="primary" disabled={!formState.isValid || loading} type="submit" variant="contained">
        <span>{t('common:Generate')}</span>
      </Button>
    </form>
  );
}
