import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { deleteEventProduct } from '../../../usecases/event/products/delete-event-product';
import { EventsState } from '../../state/events';
import { errorStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetDeleteProduct = createAction<void>('events/reset-delete-product');

const buildCrudReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(deleteEventProduct.fulfilled, (state, action) => {
    if (!state.currentEvent || state.currentEvent.id !== action.meta.arg.eventId) return state;

    return {
      ...state,
      ...successStatus('eventProductDelete'),
      currentEvent: {
        ...state.currentEvent,
        products: state.currentEvent.products.filter((product) => product.id !== action.meta.arg.productId),
      },
    };
  });
  builder.addCase(deleteEventProduct.pending, (state) => ({
    ...state,
    ...pendingStatus('eventProductDelete'),
  }));
  builder.addCase(deleteEventProduct.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventProductDelete', [action.error]),
  }));
  builder.addCase(resetDeleteProduct, (state) => ({
    ...state,
    ...notRequestedStatus('eventProductDelete'),
  }));
};

export const buildProductsReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  buildCrudReducers(builder);
};
