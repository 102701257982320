import { RenewApiKeyResponse, TenantId, V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { InviteMasterDto, TenantsGateway } from '../../core/gateways/tenants-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiTenantsGateway extends ApiGateway implements TenantsGateway {
  create(dto: V1.api.CreateTenantDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/tenants`,
        verb: 'post',
      }),
    );
  }

  generateActivationLink(
    payload: V1.api.GenerateActivationLinkRequest,
  ): Promise<V1.api.GenerateActivationLinkResponse> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: payload,
        url: `${this.apiEndpoint}/tenants/subscriptions`,
        verb: 'post',
      }),
    );
  }

  inviteMaster(payload: InviteMasterDto): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: {
          masterEmail: payload.masterEmail,
        },
        url: `${this.apiEndpoint}/tenants/${payload.tenantId}/master-invitations`,
        verb: 'post',
      }),
    );
  }

  fetchMany(): Promise<V1.api.TenantDTO[]> {
    return firstValueFrom(this.authenticatedJsonQuery<V1.api.TenantDTO[]>({ url: `${this.apiEndpoint}/tenants` }));
  }

  renewApiKey(tenantId: TenantId): Promise<RenewApiKeyResponse> {
    return firstValueFrom(
      this.authenticatedCommand<RenewApiKeyResponse>({
        body: {},
        url: `${this.apiEndpoint}/tenants/${tenantId}/renew-api-key`,
        verb: 'post',
      }),
    );
  }

  updateAlias(tenantId: TenantId, alias: string): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: { alias },
        url: `${this.apiEndpoint}/tenants/${tenantId}/alias`,
        verb: 'put',
      }),
    );
  }

  updateCurrency(tenantId: TenantId, dto: V1.api.UpdateCurrencyDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/currency`,
        verb: 'put',
      }),
    );
  }

  updateIntegrations(tenantId: TenantId, dto: V1.api.UpdateIntegrationsDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/integrations`,
        verb: 'put',
      }),
    );
  }
}
