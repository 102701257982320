import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import React from 'react';

import { CBOCatalogProduct } from '../../../../../../core/domain/CBOCatalogProduct';
import { CBOCurrency } from '../../../../../../core/domain/CBOCurrency';
import { getProductQuantity } from '../../../../../../utils/get-product-quantity';
import ProductPrice from '../../../../atoms/ProductPrice';
import ProductQuantityLabel from '../../ProductQuantityLabel';
import ProductSKU from '../../ProductSKU';

interface ProductRowProps {
  currency: CBOCurrency;
  index: number;
  isAlreadyAdded: boolean;
  isSelected: boolean;
  onAddToEvent: () => void;
  onSelect: () => void;
  onUnselect: () => void;
  product: CBOCatalogProduct;
}

export const rowHeight = 86;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:active': {
    backgroundColor: grey['100'],
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  height: `${rowHeight}px`,
  minHeight: 0,
  padding: theme.spacing(1),
}));

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none',
});

const Image = styled('img')(({ theme }) => ({
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '5px',
  flex: 1,
  objectFit: 'contain',
}));

export default function ProductRow(props: ProductRowProps) {
  const { currency, isAlreadyAdded, isSelected, onSelect, onUnselect, product } = props;

  const productPriceVariant = product.hasVariants ? 'range' : 'simple';
  const productQuantity = getProductQuantity(product);

  const handleClick = () => {
    isSelected ? onUnselect() : onSelect();
  };

  return (
    <StyledTableRow
      hover
      onClick={handleClick}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
        backgroundColor: isSelected ? grey['100'] : 'default',
        ...(isAlreadyAdded && { opacity: 0.25, pointerEvents: 'none' }),
      }}
    >
      <StyledTableCell>
        <Checkbox checked={isSelected} />
      </StyledTableCell>
      <StyledTableCell sx={{ display: 'flex', height: '70px', margin: 'auto', width: '70px' }}>
        <Image alt={product.title} src={product.imageUrls[0]} />
      </StyledTableCell>
      <StyledTableCell sx={{ flex: 1, marginLeft: (theme) => theme.spacing(2) }}>
        <Typography fontSize="1em" fontWeight="bold">
          {product.title}
        </Typography>
        <ProductQuantityLabel quantity={productQuantity} />
        {product.sku && <ProductSKU sku={product.sku} />}
      </StyledTableCell>
      <StyledTableCell sx={{ justifySelf: 'flex-end' }}>
        <ProductPrice
          currency={currency}
          price={product.price}
          sx={{ fontWeight: 'bold' }}
          variant={productPriceVariant}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}
