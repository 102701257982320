import { TenantId } from '@bellepoque/api-contracts';

import { CBOEventDepiction } from '../../domain/CBOEventDepiction';
import { CBOEventListReadModel } from '../../domain/CBOEventListReadModel';
import { CBOEventReadModel } from '../../domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../domain/CBOEventReadModelProduct';
import { CBOLiveEventStatistics, initialLiveEventStatistics } from '../../domain/CBOLiveEventStatistics';
import { CBOMagicContent } from '../../domain/CBOMagicContent';
import { CBORealtimeData } from '../../domain/CBORealtimeData';
import { CBOEventStatistics } from '../../domain/CBOTenantStatistics';
import { CBOChatCredentials } from '../../domain/Chat';
import { SecuredReplayFileUploadUrls } from '../../domain/Theme';
import { CommandResult, INITIAL_COMMAND_RESULT, INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface EventsCommandsQueriesState {
  eventChatCredentialsFetching: QueryResult;
  eventClosing: CommandResult;
  eventCreation: CommandResult;
  eventDeletion: CommandResult;
  eventFetching: QueryResult;
  eventFileUrlsFetching: QueryResult;
  eventFilesUploading: CommandResult;
  eventOpening: CommandResult;
  eventProductDelete: CommandResult;
  eventPublish: CommandResult;
  eventStatisticsFetching: QueryResult;
  eventThemeCopy: CommandResult;
  eventThemeCustomisation: CommandResult;
  eventUnPublish: CommandResult;
  eventUpdate: CommandResult;
  eventViewerTokenFetching: QueryResult;
  eventsFetching: QueryResult;
  liveEventStatisticsFetching: QueryResult;
  magicContentFetching: QueryResult;
  magicContentGenerating: CommandResult;
  pinMessage: CommandResult;
  productsDisplay: CommandResult;
  productsLineupReordering: CommandResult;
  realtimeDataFetching: QueryResult;
  replayFileUploadUrlsFetching: QueryResult;
  replayFilesUploading: CommandResult;
  replayOpening: CommandResult;
  replayPublish: CommandResult;
  replayUpdate: CommandResult;
  replaysChaptersFetching: QueryResult;
  toggleLiveViewersCountDisplayUpdating: CommandResult;
  unpinMessage: CommandResult;
}

export interface EventsState extends EventsCommandsQueriesState {
  chatCredentials: CBOChatCredentials | null;
  currentEvent: CBOEventReadModel | null;
  currentEventMagicContents: CBOMagicContent[];
  currentEventRealtimeData: CBORealtimeData | null;
  currentEventStatistics: CBOEventStatistics;
  currentEventViewerToken: string;
  currentLiveEventStatistics: CBOLiveEventStatistics;
  displayedProducts: CBOEventReadModelProduct[];
  events: CBOEventListReadModel[];
  eventsTenantId: TenantId;
  isPrivatePreview: boolean;
  isTranscriptAvailable: boolean;
  replayFileUploadUrls: SecuredReplayFileUploadUrls | null;
  upcomingEvent: CBOEventDepiction | null;
}

const initialCommandsQueriesState: EventsCommandsQueriesState = {
  eventChatCredentialsFetching: INITIAL_QUERY_RESULT,
  eventClosing: INITIAL_COMMAND_RESULT,
  eventCreation: INITIAL_COMMAND_RESULT,
  eventDeletion: INITIAL_COMMAND_RESULT,
  eventFetching: INITIAL_QUERY_RESULT,
  eventFileUrlsFetching: INITIAL_QUERY_RESULT,
  eventFilesUploading: INITIAL_COMMAND_RESULT,
  eventOpening: INITIAL_COMMAND_RESULT,
  eventProductDelete: INITIAL_COMMAND_RESULT,
  eventPublish: INITIAL_COMMAND_RESULT,
  eventStatisticsFetching: INITIAL_QUERY_RESULT,
  eventThemeCopy: INITIAL_COMMAND_RESULT,
  eventThemeCustomisation: INITIAL_COMMAND_RESULT,
  eventUnPublish: INITIAL_COMMAND_RESULT,
  eventUpdate: INITIAL_COMMAND_RESULT,
  eventViewerTokenFetching: INITIAL_QUERY_RESULT,
  eventsFetching: INITIAL_QUERY_RESULT,
  liveEventStatisticsFetching: INITIAL_QUERY_RESULT,
  magicContentFetching: INITIAL_QUERY_RESULT,
  magicContentGenerating: INITIAL_COMMAND_RESULT,
  pinMessage: INITIAL_COMMAND_RESULT,
  productsDisplay: INITIAL_COMMAND_RESULT,
  productsLineupReordering: INITIAL_COMMAND_RESULT,
  realtimeDataFetching: INITIAL_QUERY_RESULT,
  replayFileUploadUrlsFetching: INITIAL_QUERY_RESULT,
  replayFilesUploading: INITIAL_COMMAND_RESULT,
  replayOpening: INITIAL_COMMAND_RESULT,
  replayPublish: INITIAL_COMMAND_RESULT,
  replayUpdate: INITIAL_COMMAND_RESULT,
  replaysChaptersFetching: INITIAL_QUERY_RESULT,
  toggleLiveViewersCountDisplayUpdating: INITIAL_COMMAND_RESULT,
  unpinMessage: INITIAL_COMMAND_RESULT,
};

export const INITIAL_EVENT_STATISTICS: CBOEventStatistics = {
  eCommerce: {
    addedProducts: {
      live: 0,
      replay: 0,
    },
    cartsAmounts: {
      live: 0,
      replay: 0,
    },
    filledCarts: {
      live: 0,
      replay: 0,
    },
    validatedCarts: {
      live: 0,
      replay: 0,
    },
    validatedCartsAmounts: {
      live: 0,
      replay: 0,
    },
    validatedProducts: {
      live: 0,
      replay: 0,
    },
  },
  engagement: {
    chatMessages: 0,
    chatUsers: 0,
    likes: {
      live: 0,
      replay: 0,
    },
    reminders: {
      calendar: 0,
      sms: 0,
    },
    seenProductPages: {
      live: 0,
      replay: 0,
    },
  },
  productsStatistics: {},
  traffic: {
    averageAttendancePercentage: { live: 0, replay: 0 },
    averageAttendanceTime: { live: 0, replay: 0 },
    deviceCounts: { desktop: 0, mobile: 0, tablet: 0 },
    maxSimultaneousViewers: 0,
    totalAttendanceTime: { live: 0, replay: 0 },
    totalLiveDuration: 0,
    uniqueViewers: { live: 0, replay: 0 },
    views: { live: 0, replay: 0 },
  },
};

export const INITIAL_EVENTS_STATE: EventsState = {
  ...initialCommandsQueriesState,
  chatCredentials: null,
  currentEvent: null,
  currentEventMagicContents: [],
  currentEventRealtimeData: null,
  currentEventStatistics: INITIAL_EVENT_STATISTICS,
  currentEventViewerToken: '',
  currentLiveEventStatistics: initialLiveEventStatistics,
  displayedProducts: [],
  events: [],
  eventsTenantId: '',
  isPrivatePreview: true,
  isTranscriptAvailable: false,
  magicContentFetching: INITIAL_QUERY_RESULT,
  magicContentGenerating: INITIAL_COMMAND_RESULT,
  replayFileUploadUrls: null,
  upcomingEvent: null,
};
