import { Avatar } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ENDPOINTS } from '../../../../../../endpoints';
import Twitch from '../../../../../../icons/TwitchIcon';
import RTMPRestreamingSection, { RestreamingProps, RestreamingSectionProps } from './RTMPRestreamingSection';

const TwitchRestreamingSection: FC<RestreamingSectionProps> = ({
  canEnableMultistream,
  keyError,
  urlError,
  ...props
}) => {
  const { t } = useTranslation(['events', 'common']);

  const twitchRestreamingProps: RestreamingProps = useMemo(
    () => ({
      helpLink: ENDPOINTS.restreaming.twitchRestreamingHelp,
      id: 'twitch',
      keyError,
      logo: (
        <Avatar
          sx={{
            bgcolor: (theme) => (canEnableMultistream ? theme.palette.socials.twitch : theme.palette.grey[500]),
          }}
        >
          <Twitch />
        </Avatar>
      ),
      secondAccount: {
        active: true,
      },
      title: t('common:Socials.Twitch'),
      urlError,
    }),
    [canEnableMultistream, keyError, t, urlError],
  );

  return (
    <RTMPRestreamingSection
      {...props}
      canEnableMultistream={canEnableMultistream}
      restreamingProps={twitchRestreamingProps}
    />
  );
};

export default TwitchRestreamingSection;
