import { Cms, TenantId } from '@bellepoque/api-contracts';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { Modal, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MaterialMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { CBOTenant } from '../../../core/domain/CBOTenant';
import { CurrentUserType } from '../../../core/store/state/authentication';
import logo from '../../assets/logo.png';
import { NavigationTabs } from '../../navigation-tabs';
import Catalog from '../../pages/Catalog';
import YoutubeLogin from '../../pages/YoutubeLogin';
import Administration from '../../pages/administration/Administration';
import Analytics from '../../pages/analytics/Analytics';
import EventStatistics from '../../pages/analytics/EventStatistics';
import HelpAndSupport from '../../pages/help-and-support/HelpAndSupport';
import Home from '../../pages/homepage/Home';
import Integrations from '../../pages/integrations/Integrations';
import LiveEventDetails from '../../pages/live-event-details/LiveEventDetails';
import LiveEvents from '../../pages/live-events/LiveEvents';
import LiveShoppingPage from '../../pages/live-shopping-page/LiveShoppingPage';
import MediaCollections from '../../pages/media-collections/MediaCollections';
import MediaCollectionsEditor from '../../pages/media-collections/media-collections-editor/MediaCollectionsEditor';
import Onboarding from '../../pages/onboarding/Onboarding';
import ReplayDetails from '../../pages/replay-details/ReplayDetails';
import Replays from '../../pages/replays/Replays';
import Shoppables from '../../pages/shoppables/Shoppables';
import { routes } from '../../routes';
import { OnboardingModal } from '../molecules/ImageModal';
import MiniDrawer, { DrawerHeader, drawerWidth } from '../molecules/MiniDrawer';
import PublisherModal from '../molecules/PublisherModal';
import ShopifyScopesModal from '../molecules/ShopifyScopesModal';
import ResumeOnboardingModal from '../molecules/onboarding/ResumeOnboardingModal';
import { createMenuViewModel } from './Menu.viewmodel';
import Tutorial from './Tutorial';

const canAccessLiveShoppingPage = ({ cms }: CBOTenant) => cms === Cms.Shopify;

interface MenuProps {
  currentTenant: CBOTenant;
  currentTutorialStep: number;
  currentUser: CurrentUserType;
  highlightedTab: NavigationTabs | null;
  isTutorialShown: boolean;
  onChangeTenant: (tenantId: TenantId) => void;
  onCloseTutorial: () => void;
  onLogout: () => void;
  onNextTutorialStep: (params: { highlightedTab: NavigationTabs | null }) => void;
  tenants: CBOTenant[];
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const toolbarHeight = 64;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Backdrop = styled('div')({
  backgroundColor: 'rgba(13, 47, 57, 0.55)',
  display: 'flex',
  flex: '1',
  height: '100%',
  justifyContent: 'center',
  outline: 'none',
  top: '0',
  transition: 'opacity 0.5s',
  zIndex: '-1',
});

export default function Menu(props: MenuProps) {
  const viewModel = useSelector(createMenuViewModel({ dispatch: useDispatch() }));

  const {
    fetchOnboardingProgress,
    skipOnboarding,
    areShopifyScopesUpToDate,
    hasCheckedShopifyScopes,
    hasCompletedOnboarding,
    hasStartedOnboarding,
    hasFetchedOnboardingProgress,
  } = viewModel;

  const { t } = useTranslation(['common', 'events', 'onboarding', 'users']);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [isOnboardingModalShown, setIsOnboardingModalShown] = useState(false);
  const [isPostTutorialModalShown, setIsPostTutorialModalShown] = useState(false);
  const [isShopifyScopesModalShown, setIsShopifyScopesModalShown] = useState(false);
  const profileOpen = Boolean(anchorEl);
  const theme = useTheme();
  const navigate = useNavigate();
  const isOnboardingPageActive = useMatch(routes.onboarding);
  const {
    currentTenant,
    currentTutorialStep,
    currentUser,
    highlightedTab,
    isTutorialShown,
    onChangeTenant,
    onCloseTutorial,
    onLogout,
    onNextTutorialStep,
    tenants,
  } = props;

  const isLiveShoppingPageEnabled = useMemo(() => {
    if (currentTenant.id === '') return false;
    return canAccessLiveShoppingPage(currentTenant);
  }, [currentTenant]);

  const canDoOnboarding = isLiveShoppingPageEnabled;
  const shouldDoOnboarding = hasFetchedOnboardingProgress && !hasCompletedOnboarding;

  useEffect(() => {
    if (canDoOnboarding) {
      fetchOnboardingProgress();
    }
  }, [canDoOnboarding]);

  useEffect(() => {
    if (hasFetchedOnboardingProgress) {
      if (!hasCompletedOnboarding) {
        setIsOnboardingModalShown(true);
      }
    }
  }, [hasFetchedOnboardingProgress, hasCompletedOnboarding]);

  useEffect(() => {
    if (!isOnboardingPageActive) {
      if (shouldDoOnboarding) {
        setIsOnboardingModalShown(true);
      }
    }
  }, [isOnboardingPageActive]);

  useEffect(() => {
    if (hasCheckedShopifyScopes) {
      setIsShopifyScopesModalShown(!areShopifyScopesUpToDate);
    }
  }, [hasCheckedShopifyScopes, areShopifyScopesUpToDate]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClosePublisherModal = () => {
    setIsPostTutorialModalShown(false);
  };

  const handleCloseTutorial = () => {
    setIsPostTutorialModalShown(true);
    onCloseTutorial();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleGoToOnboarding = () => {
    setIsOnboardingModalShown(false);
    navigate(routes.onboarding);
  };

  const handleGoToShoppables = () => {
    skipOnboarding();
    setIsOnboardingModalShown(false);
    navigate(routes.shoppables);
  };

  const handleSkipOnboarding = () => {
    setIsOnboardingModalShown(false);
    skipOnboarding();
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShopifyScopeModalClose = () => {
    setIsShopifyScopesModalShown(false);
  };

  if (!currentTenant || currentTenant.id === '') return <div>Loading...</div>;

  const renderTutorialAndOnboarding = () => {
    if (isTutorialShown) {
      return (
        <Tutorial
          canAccessLiveShoppingPage={canAccessLiveShoppingPage(currentTenant)}
          currentStepIndex={currentTutorialStep}
          onClose={handleCloseTutorial}
          onNextStep={onNextTutorialStep}
        />
      );
    }

    if (isPostTutorialModalShown) {
      return <PublisherModal onClose={handleClosePublisherModal} />;
    }

    if (isOnboardingModalShown) {
      if (hasStartedOnboarding) {
        return <ResumeOnboardingModal onGoToOnboarding={handleGoToOnboarding} onSkip={handleSkipOnboarding} />;
      }

      return (
        <OnboardingModal
          onGoToShoppables={handleGoToShoppables}
          onSkip={handleSkipOnboarding}
          onStart={handleGoToOnboarding}
        />
      );
    }
  };

  return (
    <Box display="flex" flexGrow={1} height="100%" id="menu">
      <AppBar
        elevation={0}
        open={open}
        position="fixed"
        sx={{
          zIndex: isTutorialShown || isPostTutorialModalShown ? 'auto' : 1201,
        }}
      >
        <Toolbar sx={{ backgroundColor: theme.palette.background.default, height: `${toolbarHeight}px` }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            size="large"
            sx={{
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
            <img alt="LiveMeUp Logo" height="25px" src={logo} />
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              position: 'absolute',
              right: (theme) => theme.spacing(2),
            }}
          >
            <div>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-label="account of current user"
                onClick={handleMenu}
                size="large"
                sx={{ color: 'primary.dark' }}
              >
                <AccountCircle />
              </IconButton>
              <MaterialMenu
                anchorEl={anchorEl}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                id="menu-appbar"
                keepMounted
                onClose={handleClose}
                open={profileOpen}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                <MenuItem onClick={onLogout}>{t('users:Logout')}</MenuItem>
              </MaterialMenu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <MiniDrawer
        currentTenant={currentTenant}
        currentUser={currentUser}
        disablePointerEvents={isTutorialShown}
        enableEventsOnly={shouldDoOnboarding && !isTutorialShown}
        highlightedTab={highlightedTab}
        isLiveShoppingPageEnabled={isLiveShoppingPageEnabled}
        onChangeTenant={onChangeTenant}
        onCloseDrawer={handleDrawerClose}
        open={open}
        tenants={tenants}
      />
      <Box
        component="main"
        id="main-container"
        sx={{ flexDirection: 'column', flexGrow: 1, height: '100%', position: 'relative', width: '100%' }}
      >
        <DrawerHeader ref={(el: any) => setHeaderHeight(el?.clientHeight || 0)} />
        <Box display="flex" id="content-container" minHeight={`calc(100% - ${headerHeight}px)`} width="100%">
          <Modal
            hideBackdrop
            onClose={() => null}
            open={isOnboardingModalShown || isTutorialShown || isPostTutorialModalShown}
            sx={{ zIndex: 1199 }}
          >
            <Backdrop>{renderTutorialAndOnboarding()}</Backdrop>
          </Modal>

          <ShopifyScopesModal
            isOpen={isShopifyScopesModalShown}
            onClose={handleShopifyScopeModalClose}
            tenantName={currentTenant.name}
          />

          <Routes>
            <Route element={<Navigate replace to={routes.home} />} path="*" />

            <Route element={<Administration />} path={routes.administration.root} />
            <Route element={<Administration />} path={routes.administration.createTenant} />
            <Route element={<Administration />} path={routes.administration.integrations} />
            <Route element={<Administration />} path={routes.administration.invitations} />
            <Route element={<Administration />} path={routes.administration.tools} />
            <Route element={<Administration />} path={routes.administration.catalog} />
            <Route element={<Administration />} path={routes.administration.activationLink} />

            <Route element={<Analytics isDrawerOpen={open} />} path={routes.analytics.root} />
            <Route element={<EventStatistics />} path={routes.analytics.event({ eventId: ':eventId' })} />
            <Route element={<Analytics isDrawerOpen={open} />} path={routes.analytics.liveAndReplays} />
            <Route element={<Analytics isDrawerOpen={open} />} path={routes.analytics.shoppableVideos} />
            <Route element={<Analytics isDrawerOpen={open} />} path={routes.analytics.traffic} />
            <Route element={<Analytics isDrawerOpen={open} />} path={routes.analytics.usage} />

            <Route element={<Catalog />} path={routes.catalog} />

            <Route element={<LiveEvents isDrawerOpen={open} />} path={routes.events.root} />
            <Route element={<LiveEventDetails />} path={routes.events.details({ eventId: ':eventId' })} />
            <Route element={<LiveEventDetails />} path={routes.events.liveWithChat({ eventId: ':eventId' })} />
            <Route element={<LiveEventDetails />} path={routes.events.multistream({ eventId: ':eventId' })} />
            <Route element={<LiveEventDetails />} path={routes.events.preview({ eventId: ':eventId' })} />
            <Route element={<LiveEventDetails />} path={routes.events.products({ eventId: ':eventId' })} />
            <Route element={<LiveEventDetails />} path={routes.events.settings({ eventId: ':eventId' })} />
            <Route element={<LiveEventDetails />} path={routes.events.theme({ eventId: ':eventId' })} />

            <Route element={<HelpAndSupport />} path={routes.helpAndSupport({ slug: ':slug?' })} />

            <Route element={<Home />} path={routes.home} />

            <Route element={<Integrations />} path={routes.integrations.root} />
            <Route element={<Integrations />} path={routes.integrations.klaviyo} />
            <Route element={<Integrations />} path={routes.integrations.napps} />
            <Route element={<Integrations />} path={routes.integrations.tapcart} />

            <Route element={<Onboarding />} path={routes.onboarding} />

            {isLiveShoppingPageEnabled && (
              <Fragment>
                <Route element={<MediaCollections />} path={routes.playlists.root} />
                <Route
                  element={<MediaCollectionsEditor />}
                  path={routes.playlists.mediaCollection({ mediaCollectionId: ':mediaCollectionId' })}
                />
                <Route
                  element={<MediaCollectionsEditor />}
                  path={routes.playlists.new({ mediaCollectionMediaId: ':mediaCollectionMediaId?' })}
                />
              </Fragment>
            )}

            <Route element={<ReplayDetails />} path={routes.replays.analytics({ eventId: ':eventId' })} />
            <Route element={<ReplayDetails />} path={routes.replays.analytics({ eventId: ':eventId' })} />
            <Route element={<ReplayDetails />} path={routes.replays.details({ eventId: ':eventId' })} />
            <Route element={<ReplayDetails />} path={routes.replays.magicContents({ eventId: ':eventId' })} />
            <Route element={<ReplayDetails />} path={routes.replays.preview({ eventId: ':eventId' })} />
            <Route element={<ReplayDetails />} path={routes.replays.replayLinks({ eventId: ':eventId' })} />
            <Route element={<Replays isDrawerOpen={open} />} path={routes.replays.root} />

            <Route element={<Shoppables />} path={routes.shoppables} />

            <Route element={<YoutubeLogin />} path={routes.youtubeLogin} />

            {isLiveShoppingPageEnabled && (
              <Route element={<LiveShoppingPage isDrawerOpen={open} />} path={routes.liveShoppingPage} />
            )}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
