import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { State } from '../../../../../core/store';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import ProductList from './ProductList';

type ProductsProps = {
  event: CBOEventReadModel;
  hasCatalogProducts: boolean;
  onAddProduct: () => void;
  readonly: boolean;
};

export default function ProductPanel({ event, hasCatalogProducts, onAddProduct, readonly }: ProductsProps) {
  const dispatch = useDispatch();
  const { currentTenant, tenantProductsFetching } = useSelector((state: State) => state.tenants);
  const products = event.products ?? [];

  // Wait for tenant products to be retrieved from firebase
  const productsLoading = tenantProductsFetching.status !== 'loaded';

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': currentTenant.id,
          'Tenant name': currentTenant.name,
        },
        name: 'Product panel',
      }),
    );
  }, []);

  return (
    <ProductList
      currency={currentTenant.currency}
      eventId={event.id}
      hasCatalogProducts={hasCatalogProducts}
      onAddProduct={onAddProduct}
      products={products}
      productsLoading={productsLoading}
      readonly={readonly}
    />
  );
}
