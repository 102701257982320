import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, TableCell, TableRow, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import DraggableIcon from '../../../../icons/DraggableIcon';
import { getProductQuantity } from '../../../../utils/get-product-quantity';
import { getSmallestVariantPrice } from '../../../../utils/variants';
import ProductPrice from '../../atoms/ProductPrice';
import ProductQuantityLabel from './ProductQuantityLabel';
import ProductSKU from './ProductSKU';

const StyledCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  flex: 1,
  padding: theme.spacing(1),
}));

const DragCell = styled(StyledCell)({
  height: '60px',
  width: '40px',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:active': {
    backgroundColor: grey['100'],
    width: '100%',
  },
  borderTop: `1px solid ${theme.palette.divider}`,
  minHeight: 0,
  padding: theme.spacing(1),
}));

const Image = styled('img')(({ theme }) => ({
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '5px',
  flex: 1,
  objectFit: 'contain',
}));

interface ProductRowProps {
  currency: CBOCurrency;
  index: number;
  isPending: boolean;
  onDelete: () => void;
  product: CBOEventReadModelProduct;
  readonly: boolean;
}

export default function ProductRow(props: ProductRowProps) {
  const { currency, index, isPending, onDelete, product, readonly } = props;
  const variants = product.options?.variants ?? [];
  const hasVariant = variants.length > 0;
  const productPriceVariant = hasVariant ? 'range' : 'simple';
  const productPrice = hasVariant ? getSmallestVariantPrice(variants) : product.price;
  const productQuantity = getProductQuantity(product);

  const handleDelete = (e: any) => {
    onDelete();
    e.stopPropagation();
  };

  return (
    <Draggable draggableId={product.id} index={index} isDragDisabled={isPending || readonly} key={product.id}>
      {(provided) => (
        <StyledTableRow hover {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          {!readonly && (
            <DragCell align="center">
              <Box maxWidth="40px">
                <DraggableIcon />
              </Box>
            </DragCell>
          )}
          <StyledCell align="center" width="100px">
            <Box sx={{ display: 'flex', height: '100px', margin: 'auto', width: '100px' }}>
              <Image alt={product.title} src={product.imageUrls[0]} />
            </Box>
          </StyledCell>
          <StyledCell>
            <Typography fontSize="1em" fontWeight="bold">
              {product.title}
            </Typography>
            <ProductQuantityLabel quantity={productQuantity} />
            {product.sku && <ProductSKU sku={product.sku} />}
          </StyledCell>
          <StyledCell align="right">
            <ProductPrice
              currency={currency}
              price={productPrice}
              sx={{ fontWeight: 'bold' }}
              variant={productPriceVariant}
            />
          </StyledCell>
          {!readonly && (
            <StyledCell align="center" width="100px">
              <IconButton aria-label="delete" onClick={handleDelete} size="large" sx={{ color: '#000' }}>
                <DeleteIcon />
              </IconButton>
            </StyledCell>
          )}
        </StyledTableRow>
      )}
    </Draggable>
  );
}
