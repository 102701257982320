import { V1 } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const generateActivationLink = createAppAsyncThunk(
  'administration/generate-activation-link',
  async (
    request: V1.api.GenerateActivationLinkRequest,
    { extra: { tenantsGateway } },
  ): Promise<V1.api.GenerateActivationLinkResponse> => {
    return tenantsGateway.generateActivationLink(request);
  },
);
