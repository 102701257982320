import { Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ProductSKUContainer = styled(Typography)({
  fontSize: '0.8em',
});

export type ProductSKUProps = {
  sku: string;
};

const ProductSKU: FC<ProductSKUProps> = ({ sku }) => {
  const { t } = useTranslation('products');

  return (
    <ProductSKUContainer>
      {t('sku')}: {sku}
    </ProductSKUContainer>
  );
};

export default ProductSKU;
