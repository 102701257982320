import { Link, Typography, linkClasses, styled } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ENDPOINTS } from '../../../../endpoints';

const RestreamingRulesWarning = styled(Typography)(({ theme }) => ({
  fontSize: '0.8em',

  [`& > .${linkClasses.root}`]: {
    textDecorationColor: theme.palette.common.black,
  },
}));

const FacebookRestreamingRulesWarning = () => {
  const { t } = useTranslation('events', { keyPrefix: 'FacebookRestreaming' });

  return (
    <RestreamingRulesWarning>
      <Trans
        components={{
          enableFeatureLink: <Link href={ENDPOINTS.facebookRestreamingRules} target="_blank" />,
        }}
        i18nKey="RestreamingRulesWarning"
        t={t}
      />
    </RestreamingRulesWarning>
  );
};

export default FacebookRestreamingRulesWarning;
