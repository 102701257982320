import { miniSerializeError } from '@reduxjs/toolkit';
import { NEVER, of, race } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, timeout } from 'rxjs/operators';

import { AppEpic } from '../..';
import { toCBOProduct } from '../../../domain/CBOEventReadModelProduct';
import { synchronizeOneEventFulfilled } from '../../slices/event/event-synchronize.slice';
import { synchronizeTenantProductsFulfilled, synchronizeTenantProductsRejected } from '../../slices/tenants.slice';
import { ofType } from '../utils';

export const synchronizeTenantProducts: AppEpic = (action$, state$, { container: { realtimeDataGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof synchronizeOneEventFulfilled>>(synchronizeOneEventFulfilled.type),
    distinctUntilChanged((prev, cur) => prev.payload.id === cur.payload.id),
    switchMap((action) =>
      race(
        realtimeDataGateway.getTenantProductsByEventId(action.payload.tenantId, action.payload.id),
        NEVER.pipe(timeout(3000)),
      ).pipe(
        map((products) => ({
          eventId: action.payload.id,
          products: products.map((product) => toCBOProduct(product)),
          tenantId: action.payload.tenantId,
        })),
      ),
    ),
    map(({ eventId, products, tenantId }) => synchronizeTenantProductsFulfilled({ eventId, products, tenantId })),
    catchError((error) => of(synchronizeTenantProductsRejected([miniSerializeError(error)]))),
  );
};
