import { V1 } from '@bellepoque/api-contracts';

import { CommandResult, INITIAL_COMMAND_RESULT, INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface AdministrationCommandsQueriesState {
  createTenant: CommandResult;
  findEvent: QueryResult;
  generateActivationLink: CommandResult;
  generateActivationLinkResult: V1.api.GenerateActivationLinkResponse | null;
  masterInvitation: CommandResult;
}

export interface AdministrationState extends AdministrationCommandsQueriesState {
  foundEvent: V1.api.EventDTO | null;
}

export const INITIAL_ADMINISTRATION_STATE: AdministrationState = {
  createTenant: INITIAL_COMMAND_RESULT,
  findEvent: INITIAL_QUERY_RESULT,
  foundEvent: null,
  generateActivationLink: INITIAL_COMMAND_RESULT,
  generateActivationLinkResult: null,
  masterInvitation: INITIAL_COMMAND_RESULT,
};
