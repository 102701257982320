const shopifyAppName = process.env.REACT_APP_SHOPIFY_APP_NAME;

const TAP_CART_DOMAIN = 'https://cdn.livemeup.io';

export const ENDPOINTS = {
  facebookRestreamingRules: 'https://www.facebook.com/business/help/216491699144904?id=1123223941353904',
  googleApiServicesUserDataPolicy: 'https://developers.google.com/terms/api-services-user-data-policy',
  magicContentFeedback: 'https://tally.so/r/wvNQxQ',
  pricing: 'https://livemeup.io/pricing',
  publisherStoreUrls: {
    appStore: 'https://apps.apple.com/us/app/bellepoque/id1610153616',
    playStore: 'https://play.google.com/store/apps/details?id=io.bellepoque.publishing',
  },
  restreaming: {
    instagramRestreamingHelp: 'https://help.livemeup.io/en/article/restreaming-to-instagram-6j1n7h/',
    tiktokRestreamingHelp: 'https://help.livemeup.io/en/article/multistream-to-tiktok-1mw2fl3/',
    twitchRestreamingHelp: 'https://help.livemeup.io/en/article/multistream-to-any-social-platform-linkedin-x-gra3of/',
  },
  shopifyMenu: (shopName: string) => `https://admin.shopify.com/store/${shopName}/menus`,
  shopifyPricing: (shopName: string) => `https://admin.shopify.com/store/${shopName}/apps/${shopifyAppName}/billing`,
  support: {
    createEvent: 'article/create-a-live-event-1eycou0',
    createShoppableVideo: 'category/shoppable-videos-1cyeslp',
    enableShoppableVideoPlayer: 'article/enable-the-shoppable-video-player-on-product-page-ehayhe',
    home: 'https://help.livemeup.io/en/',
  },
  tapcart: {
    createWebBasedScreen: 'https://app.tapcart.com/custom-screens/web-based?q=create',
    enableLMUIntegration: 'https://app.tapcart.com/integrations/livemeup',
    liveShoppingPageUrl: (tenantName: string) => `${TAP_CART_DOMAIN}/landing-page-tapcart.html?shop=${tenantName}`,
    playlistsBlocUrl: `${TAP_CART_DOMAIN}/player-tapcart.html`,
  },
  youtubeAccountFeatures: 'https://www.youtube.com/features',
};
