import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Skeleton,
  Typography,
  chipClasses,
  linearProgressClasses,
  styled,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PlanIcon from '../../../../icons/PlanIcon';
import CreateButton from '../CreateButton';

type CounterWithUpgradeButtonProps = {
  actions?: React.ReactNode;
  count: number;
  exceededCountMessage?: string;
  hideUpgradeButton?: boolean;
  loading: boolean;
  maxCount: number | null;
  message: string;
  onUpgrade?: () => void;
  title: string;
  usePositiveCompletionBarColors?: boolean;
};

const ActionsContainer = styled(Box)({
  alignItems: 'flex-end',
  display: 'flex',
  flex: 1 / 3,
  maxHeight: '70px',
  width: '100%',
});

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  textAlign: 'initial',
}));

const ContentContainer = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const Title = styled(Typography)({
  fontSize: `1.2em`,
  fontWeight: 'bold',
  lineHeight: 1.5,
});

const TitleContainer = styled(Box)<{ isChipShown?: boolean }>({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between',
});

const ProgressBarContainer = styled(Box)({
  flex: 1,
});

// TODO: Move typography style to theme and pass them to skeletons
const countFontSize = '.9em';
const Count = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: countFontSize,
}));

const CompletedChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  [`& .${chipClasses.label}`]: {
    color: theme.palette.common.white,
    fontSize: '10px',
    fontWeight: 'bold',
  },
}));

const StyledLinearProgress = styled(LinearProgress)<{
  completionPercentage: number;
  usePositiveCompletionBarColors: boolean;
}>(({ completionPercentage, theme, usePositiveCompletionBarColors }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '10px',
  height: '8px',
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  ...(usePositiveCompletionBarColors && {
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor:
        completionPercentage < 25
          ? theme.palette.secondary.main
          : completionPercentage < 75
          ? theme.palette.primary.main
          : theme.palette.success.main,
    },
  }),
}));

function CounterWithUpgradeButton({
  actions,
  count,
  loading,
  exceededCountMessage,
  hideUpgradeButton,
  maxCount,
  message,
  onUpgrade,
  title,
  usePositiveCompletionBarColors = false,
}: CounterWithUpgradeButtonProps) {
  const { t } = useTranslation(['home', 'common']);

  const hasExceededCount = !!maxCount && count >= maxCount;
  const hasActions = !hideUpgradeButton || !!actions;

  const completionPercentage = maxCount ? (hasExceededCount ? 100 : (count / maxCount) * 100) : 0;

  const renderContent = useCallback(
    () => (
      <>
        <ContentContainer>
          <TitleContainer mb={1}>
            <Title>{title}</Title>
            {hasExceededCount && <CompletedChip label={t('common:Completed')} size="small" />}
          </TitleContainer>
          <ProgressBarContainer>
            {!!maxCount && (
              <StyledLinearProgress
                color="primary"
                completionPercentage={completionPercentage}
                usePositiveCompletionBarColors={usePositiveCompletionBarColors}
                value={completionPercentage}
                variant="determinate"
              />
            )}
            <Count>{hasExceededCount ? exceededCountMessage : message}</Count>
          </ProgressBarContainer>
        </ContentContainer>
        {hasActions && (
          <ActionsContainer>
            {!hideUpgradeButton && (
              <Button
                color="primary"
                fullWidth
                onClick={onUpgrade}
                startIcon={<PlanIcon />}
                sx={{ height: '100%' }}
                variant="outlined"
              >
                {t('UpgradeForMore')}
              </Button>
            )}
            {actions}
          </ActionsContainer>
        )}
      </>
    ),
    [exceededCountMessage, hasExceededCount, message, onUpgrade, title],
  );

  const renderLoadingContent = () => (
    <>
      <ContentContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <Skeleton height="8px" variant="rectangular" />
        <Skeleton sx={{ fontSize: countFontSize }} variant="text" />
      </ContentContainer>
      <ActionsContainer>
        <Skeleton height="40px" sx={{ borderRadius: '10px' }} variant="rectangular" width="175px" />
      </ActionsContainer>
    </>
  );

  return <Container boxShadow={3}>{loading ? renderLoadingContent() : renderContent()}</Container>;
}

type ShoppablesCountProps = Pick<CounterWithUpgradeButtonProps, 'count' | 'loading' | 'maxCount' | 'onUpgrade'> & {
  onCreateShoppableVideo: () => void;
};

export const ShoppablesCount = (props: ShoppablesCountProps) => {
  const { t } = useTranslation(['home', 'shoppables']);
  const { count, maxCount, onCreateShoppableVideo } = props;

  return (
    <CounterWithUpgradeButton
      {...props}
      actions={
        count === 0 && (
          <CreateButton fullWidth onClick={onCreateShoppableVideo} text={t('shoppables:CreateShoppable')} />
        )
      }
      exceededCountMessage={t('ShoppablesCount', { count })}
      hideUpgradeButton={count === 0 || maxCount === null}
      message={maxCount ? t('ShoppablesCountWithMaxCount', { count, maxCount }) : t('ShoppablesCount', { count })}
      title={t('ShoppableVideos')}
    />
  );
};

type ProductPagesWithMediaCollectionsCountProps = Pick<
  CounterWithUpgradeButtonProps,
  'actions' | 'count' | 'loading' | 'maxCount'
> & {
  onCreateMediaCollection: () => void;
};

export const ProductPagesWithMediaCollectionsCount = (props: ProductPagesWithMediaCollectionsCountProps) => {
  const { t } = useTranslation(['home', 'mediaCollections']);
  const { count, maxCount, onCreateMediaCollection } = props;

  return (
    <CounterWithUpgradeButton
      {...props}
      actions={<CreateButton fullWidth onClick={onCreateMediaCollection} text={t('mediaCollections:CreateOne')} />}
      hideUpgradeButton
      message={t('ProductPagesWithMediaCollectionsCount', { count, maxCount })}
      title={t('ProductPagesWithMediaCollectionsCountTitle')}
      usePositiveCompletionBarColors
    />
  );
};

type RestreamingsCountProps = Pick<CounterWithUpgradeButtonProps, 'count' | 'loading' | 'maxCount' | 'onUpgrade'>;

export const RestreamingsCount = (props: RestreamingsCountProps) => {
  const { t } = useTranslation(['events']);
  const { count, maxCount } = props;

  return (
    <CounterWithUpgradeButton
      {...props}
      exceededCountMessage={t('RestreamingsCount', { count })}
      message={maxCount ? t('RestreamingsCountWithMaxCount', { count, maxCount }) : t('RestreamingsCount', { count })}
      title={t('Restreamings')}
    />
  );
};
