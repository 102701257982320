import { EventId, EventStatus, TenantId } from '@bellepoque/api-contracts';
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { CBOEventListReadModel } from '../../../domain/CBOEventListReadModel';
import { CBOEventReadModel } from '../../../domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../../domain/CBOEventReadModelProduct';
import { initialLiveEventStatistics } from '../../../domain/CBOLiveEventStatistics';
import { EventsState, INITIAL_EVENT_STATISTICS } from '../../state/events';
import { errorStatus, loadedStatus, pendingStatus } from '../../utils';
import { synchronizeTenantProductsFulfilled } from '../tenants.slice';

export const synchronizeOneEventFulfilled = createAction<CBOEventReadModel>('events/synchronize-one-event-fulfilled');
export const synchronizeOneEventPending = createAction<{ eventId: EventId; tenantId: TenantId }>(
  'events/synchronize-one-event-pending',
);
export const synchronizeOneEventRejected = createAction<any[]>('events/synchronize-one-event-rejected');

export const synchronizeEventsFulfilled = createAction<CBOEventListReadModel[]>('events/synchronize-events-fulfilled');
export const synchronizeEventsPending = createAction<{ force: boolean; tenantId: TenantId }>(
  'events/synchronize-events-pending',
);
export const synchronizeEventsRejected = createAction<any[]>('events/synchronize-events-rejected');

export type EventSynchronizeActions =
  | ReturnType<typeof synchronizeEventsFulfilled>
  | ReturnType<typeof synchronizeEventsPending>
  | ReturnType<typeof synchronizeEventsRejected>
  | ReturnType<typeof synchronizeOneEventFulfilled>
  | ReturnType<typeof synchronizeOneEventPending>
  | ReturnType<typeof synchronizeOneEventRejected>;

export const buildSynchronizeReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(synchronizeOneEventFulfilled, (state, action) => {
    // Products are assigned to the event when it is fetched for the first time, otherwise if the event is already in the state, keep the products
    const products = state.currentEvent?.id === action.payload.id ? state.currentEvent.products : [];

    return {
      ...state,
      ...loadedStatus('eventFetching'),
      currentEvent: {
        ...action.payload,
        products,
      },
      isPrivatePreview: action.payload.status !== EventStatus.PLANNED,
    };
  });
  builder.addCase(synchronizeOneEventPending, (state, action) => {
    if (action.payload.eventId === state.currentEvent?.id) {
      return state;
    }
    return {
      ...state,
      currentEvent: null,
      currentEventStatistics: INITIAL_EVENT_STATISTICS,
      currentLiveEventStatistics: initialLiveEventStatistics,
      ...pendingStatus('eventFetching'),
    };
  });
  builder.addCase(synchronizeOneEventRejected, (state, action) => ({
    ...state,
    ...errorStatus('eventFetching', action.payload),
    currentEvent: null,
  }));
  builder.addCase(synchronizeEventsFulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('eventsFetching'),
    events: action.payload,
  }));
  builder.addCase(synchronizeEventsPending, (state, action) => {
    if (state.eventsFetching.status === 'loaded' && action.payload.tenantId === state.eventsTenantId) {
      return state;
    }

    return {
      ...state,
      ...pendingStatus('eventsFetching'),
      eventsTenantId: action.payload.tenantId,
    };
  });
  builder.addCase(synchronizeEventsRejected, (state, action) => ({
    ...state,
    ...errorStatus('eventsFetching', action.payload),
    events: [],
    eventsTenantId: '',
  }));
  builder.addCase(synchronizeTenantProductsFulfilled, (state, action) => {
    if (!state.currentEvent || state.currentEvent?.id !== action.payload.eventId) {
      return state;
    }

    const orderedProducts = state.currentEvent.productIds.reduce((acc: CBOEventReadModelProduct[], productId) => {
      const product = action.payload.products.find((product) => product.id === productId);
      if (product) {
        acc.push(product);
      }

      return acc;
    }, []);

    return {
      ...state,
      currentEvent: {
        ...state.currentEvent,
        products: orderedProducts,
      },
    };
  });
};
