import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function TwitchIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      clipRule="evenodd"
      fillRule="evenodd"
      height="100px"
      shapeRendering="geometricPrecision"
      version="1.1"
      viewBox="0 0 256 268"
      width="100px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m17.4579119 0-17.4579119 46.5559188v186.2013682h63.9826001v34.933669h34.9318852l34.8970857-34.933669h52.360351l69.828078-69.803094v-162.954193zm23.2587749 23.2632364h192.0147232v128.0285536l-40.738995 40.741671h-63.992415l-34.88727 34.885486v-34.885486h-52.3960432zm64.0082982 116.4051446h23.274837v-69.824509h-23.274837zm63.996877 0h23.270375v-69.824509h-23.270375z" />
    </SvgIcon>
  );
}
