import { BillingPlanId } from '@bellepoque/api-contracts';
import { Box, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../core/domain/CBOCurrency';
import { CBOEventDepiction } from '../../core/domain/CBOEventDepiction';
import { CommandStatus } from '../../core/store/state/utils';
import ReleaseNotes from '../components/atoms/ReleaseNotes';
import ShopifyButton from '../components/atoms/ShopifyButton';
import { PlanIndicator } from '../components/atoms/home/PlanIndicator';
import { ActionNotificationProps } from '../components/molecules/ActionNotification';
import ActionCards from '../components/molecules/home/ActionCards';
import ActivityOverview from '../components/molecules/home/ActivityOverview';
import GetStartedWithLiveShopping from '../components/molecules/home/GetStartedWithLiveShopping';
import GetStartedWithShoppables from '../components/molecules/home/GetStartedWithShoppables';
import LivesAndShoppablesCounters from '../components/molecules/home/LivesAndShoppablesCounters';
import Notifications from '../components/molecules/home/Notifications';
import { HomeNotificationId } from '../pages/homepage/Home';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

const Title = styled(Typography)({
  fontSize: '2.5em',
});

const Header = styled(Box)({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
});

type Notification = ActionNotificationProps & { id: HomeNotificationId };

type HomeTemplateProps = {
  cmsLandingPageCreationStatus: CommandStatus;
  currency: CBOCurrency;
  currentPlanId: BillingPlanId;
  hasShopify: boolean;
  isProductPagesWithMediaCollectionsCountShown: boolean;
  isPublishedShoppablesCountShown: boolean;
  loading: boolean;
  maxPublishedShoppablesCount: number | null;
  onCloseNotification: (notificationId: HomeNotificationId) => void;
  onCopyEventLink: (eventId: string) => void;
  onCreateCmsLandingPage: () => void;
  onCreateEvent: () => void;
  onCreateMediaCollection: () => void;
  onCreateShoppableVideo: () => void;
  onGoToEventSettings: (eventId: string) => void;
  onGoToShopifyMenu: () => void;
  onSeeAllPlans: () => void;
  onShowConfigureLiveShoppingPageMenuModal: () => void;
  onShowEventTutorial: () => void;
  onShowPlayerPlaylistsBlockHelpModal: () => void;
  onShowShoppableVideoTutorial: () => void;
  productPagesWithMediaCollectionsCount: number;
  productsCount: number;
  publishedShoppablesCount: number;
  purchasedAmounts: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
  purchasedCarts: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
  shownNotificationsIds: HomeNotificationId[];
  upcomingEvent: CBOEventDepiction | null;
  usesSales: boolean;
  validatedCarts: {
    live: number;
    replay: number;
  };
  validatedCartsAmounts: {
    live: number;
    replay: number;
  };
  views: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
};

export default function HomeTemplate({
  cmsLandingPageCreationStatus,
  currency,
  currentPlanId,
  hasShopify,
  isPublishedShoppablesCountShown,
  isProductPagesWithMediaCollectionsCountShown,
  loading,
  maxPublishedShoppablesCount,
  onCloseNotification,
  onCopyEventLink,
  onCreateCmsLandingPage,
  onCreateEvent,
  onCreateMediaCollection,
  onCreateShoppableVideo,
  onGoToEventSettings,
  onGoToShopifyMenu,
  onSeeAllPlans,
  onShowConfigureLiveShoppingPageMenuModal,
  onShowEventTutorial,
  onShowPlayerPlaylistsBlockHelpModal,
  onShowShoppableVideoTutorial,
  productPagesWithMediaCollectionsCount,
  productsCount,
  publishedShoppablesCount,
  shownNotificationsIds,
  purchasedAmounts,
  purchasedCarts,
  validatedCartsAmounts,
  views,
  upcomingEvent,
  usesSales,
  validatedCarts,
}: HomeTemplateProps) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { t } = useTranslation(['home', 'common']);

  const hasGetStartedWithLiveShoppingCardsVisible = shownNotificationsIds.includes('landing-page-created');
  const hasGetStartedWithShoppablesCardsVisible = shownNotificationsIds.includes('no-player-playlists-block');

  useEffect(() => {
    const notifications: Notification[] = [];

    if (shownNotificationsIds.includes('landing-page-not-found')) {
      notifications.push({
        actions: (
          <ShopifyButton disabled={cmsLandingPageCreationStatus === 'pending'} onClick={onCreateCmsLandingPage}>
            {t('CreateLiveShoppingPage')}
          </ShopifyButton>
        ),
        id: 'landing-page-not-found',
        text: t('Notifications.ShoppingPageNotFound'),
        variant: 'warning',
      });
    }

    setNotifications(notifications);
  }, [cmsLandingPageCreationStatus, onCreateCmsLandingPage, onGoToShopifyMenu, shownNotificationsIds]);

  return (
    <Container>
      <InnerContainer>
        <Box>
          <Header>
            <Title fontWeight="bold" variant="h1">
              {t('Hello')}
            </Title>
            <PlanIndicator currentPlanId={currentPlanId} hasShopify={hasShopify} onSeeAllPlans={onSeeAllPlans} />
          </Header>

          <Notifications notificationsProps={notifications} />

          <Box mt={3}>
            <ReleaseNotes version="v0.20" />
          </Box>

          {hasGetStartedWithLiveShoppingCardsVisible && (
            <GetStartedWithLiveShopping
              onCloseNotification={onCloseNotification}
              onShowConfigureLiveShoppingPageMenuModal={onShowConfigureLiveShoppingPageMenuModal}
              shownNotificationsIds={shownNotificationsIds}
            />
          )}

          {hasGetStartedWithShoppablesCardsVisible && (
            <GetStartedWithShoppables
              onCloseNotification={onCloseNotification}
              onShowPlayerPlaylistsBlockHelpModal={onShowPlayerPlaylistsBlockHelpModal}
              shownNotificationsIds={shownNotificationsIds}
            />
          )}

          <Box display="flex" flexDirection="column" gap={3}>
            {usesSales ? (
              <ActivityOverview
                currency={currency}
                loading={loading}
                purchasedAmounts={purchasedAmounts}
                purchasedCarts={purchasedCarts}
                usesSales={usesSales}
                views={views}
              />
            ) : (
              <ActivityOverview
                currency={currency}
                loading={loading}
                usesSales={usesSales}
                validatedCarts={validatedCarts}
                validatedCartsAmounts={validatedCartsAmounts}
                views={views}
              />
            )}

            <ActionCards
              onCreateEvent={onCreateEvent}
              onCreateShoppableVideo={onCreateShoppableVideo}
              onShowEventTutorial={onShowEventTutorial}
              onShowShoppableVideoTutorial={onShowShoppableVideoTutorial}
            />

            <LivesAndShoppablesCounters
              hasShopify={hasShopify}
              isProductPagesWithMediaCollectionsCountShown={isProductPagesWithMediaCollectionsCountShown}
              isPublishedShoppablesCountShown={isPublishedShoppablesCountShown}
              loading={loading}
              maxPublishedShoppablesCount={maxPublishedShoppablesCount}
              onCopyEventLink={onCopyEventLink}
              onCreateEvent={onCreateEvent}
              onCreateMediaCollection={onCreateMediaCollection}
              onCreateShoppableVideo={onCreateShoppableVideo}
              onGoToEventSettings={onGoToEventSettings}
              onUpgrade={onSeeAllPlans}
              productPagesWithMediaCollectionsCount={productPagesWithMediaCollectionsCount}
              productsCount={productsCount}
              publishedShoppablesCount={publishedShoppablesCount}
              upcomingEvent={upcomingEvent}
            />
          </Box>
        </Box>
      </InnerContainer>
    </Container>
  );
}
