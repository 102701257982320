import { V1, YoutubeAccessToken } from '@bellepoque/api-contracts';
import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { addHours } from 'date-fns';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { CBOFacebookPage, CBOFacebookUser } from '../../../../../core/domain/CBOFacebookAccount';
import { CBOConnectedYoutubeUser } from '../../../../../core/domain/CBOYoutubeChannel';
import {
  CBOFacebookRestreamingConfiguration,
  CBOYoutubeRestreamingConfiguration,
} from '../../../../../core/domain/Restreaming';
import { CommandStatus, QueryStatus } from '../../../../../core/store/state/utils';
import { lengthRules } from '../../../../../utils/validation';
import StickyActionBarWrapper from '../../../../templates/StickyActionBarWrapper';
import MultistreamActionBar from '../../../molecules/event/FormActionBar';
import FacebookRestreamingSection from '../../../molecules/event/multistream/facebook-restreaming/FacebookRestreamingSection';
import InstagramRestreamingSection from '../../../molecules/event/multistream/rtmp-restreaming/InstagramRestreamingSection';
import OtherRTMPSourceRestreamingSection from '../../../molecules/event/multistream/rtmp-restreaming/OtherRTMPSourceRestreamingSection';
import TiktokRestreamingSection from '../../../molecules/event/multistream/rtmp-restreaming/TiktokRestreamingSection';
import TwitchRestreamingSection from '../../../molecules/event/multistream/rtmp-restreaming/TwitchRestreamingSection';
import YoutubeRestreamingSection from '../../../molecules/event/multistream/youtube-restreaming/YoutubeRestreamingSection';

const constraints = V1.api.constraints.events;

const PREFIX = 'MultistreamForm';

const classes = {
  errorMessage: `${PREFIX}-errorMessage`,
};

const Root = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',

  [`& .${classes.errorMessage}`]: {
    color: theme.palette.error,
  },
}));

type ContainerProps = {
  disabled: boolean;
};

const Container = styled(Box)<ContainerProps>(({ disabled, theme }) => ({
  backgroundColor: disabled ? theme.palette.grey[300] : theme.palette.common.white,
  borderRadius: '20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export type UpdateMultistreamInputs = Pick<
  CBOEventReadModel,
  'facebookRestreamingConfiguration' | 'restreamingConfigurations' | 'youtubeRestreamingConfiguration'
>;

interface MultistreamFormProps {
  canEnableMultistream: boolean;
  event: CBOEventReadModel;
  facebookIntegrationProps: {
    initStatus: CommandStatus;
    isConnecting: boolean;
    onConnect: () => void;
    onDisconnect: () => void;
    onInit: () => void;
    pages: CBOFacebookPage[];
    tokenExpiresAt: Date | null;
    user: CBOFacebookUser | null;
    userAccessToken: string | null;
  };
  isMaximumRestreamingsReached: boolean;
  onAddRestreaming: () => void;
  onRemoveRestreaming: () => void;
  onUpdateEvent: (values: UpdateMultistreamInputs) => void;
  readonly: boolean;
  submissionInProgress: boolean;
  youtubeIntegrationProps: {
    fetchLoginUrlStatus: QueryStatus;
    loginUrl: string | null;
    onDisconnect: () => void;
    onFetchChannels: (token: YoutubeAccessToken) => void;
    onFetchLoginUrl: () => void;
    onResetFetchYoutubeLoginUrl: () => void;
    user: CBOConnectedYoutubeUser | null;
  };
}

export type MultistreamFormInputs = {
  facebookRestreamingConfiguration: CBOFacebookRestreamingConfiguration | null;
  instagramMainAccountRestreamingEnabled: boolean;
  instagramMainAccountRestreamingKey: string;
  instagramMainAccountRestreamingUrl: string;
  instagramSecondAccountRestreamingEnabled: boolean;
  instagramSecondAccountRestreamingKey: string;
  instagramSecondAccountRestreamingUrl: string;
  otherRTMPSourceRestreamingEnabled: boolean;
  otherRTMPSourceRestreamingKey: string;
  otherRTMPSourceRestreamingUrl: string;
  tiktokMainAccountRestreamingEnabled: boolean;
  tiktokMainAccountRestreamingKey: string;
  tiktokMainAccountRestreamingUrl: string;
  tiktokSecondAccountRestreamingEnabled: boolean;
  tiktokSecondAccountRestreamingKey: string;
  tiktokSecondAccountRestreamingUrl: string;
  twitchMainAccountRestreamingEnabled: boolean;
  twitchMainAccountRestreamingKey: string;
  twitchMainAccountRestreamingUrl: string;
  twitchSecondAccountRestreamingEnabled: boolean;
  twitchSecondAccountRestreamingKey: string;
  twitchSecondAccountRestreamingUrl: string;
  youtubeRestreamingConfiguration: CBOYoutubeRestreamingConfiguration | null;
};

export default function MultistreamForm({
  canEnableMultistream,
  event,
  facebookIntegrationProps,
  isMaximumRestreamingsReached,
  onAddRestreaming,
  onRemoveRestreaming,
  onUpdateEvent,
  readonly,
  submissionInProgress,
  youtubeIntegrationProps,
}: MultistreamFormProps) {
  const [instagramMainAccountRestreamingConfiguration, instagramSecondAccountRestreamingConfiguration] =
    event.restreamingConfigurations.filter(({ type }) => type === V1.api.RestreamingPlatformSchema.enum.Instagram);

  const [tiktokMainAccountRestreamingConfiguration, tiktokSecondAccountRestreamingConfiguration] =
    event.restreamingConfigurations.filter(({ type }) => type === V1.api.RestreamingPlatformSchema.enum.TikTok);

  const [twitchMainAccountRestreamingConfiguration, twitchSecondAccountRestreamingConfiguration] =
    event.restreamingConfigurations.filter(({ type }) => type === V1.api.RestreamingPlatformSchema.enum.Twitch);

  const otherRTMPRestreamingConfiguration = event.restreamingConfigurations.find(
    ({ type }) => type === V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source,
  );

  const defaultValues = (event: CBOEventReadModel): MultistreamFormInputs => {
    return {
      facebookRestreamingConfiguration: event.facebookRestreamingConfiguration || null,
      instagramMainAccountRestreamingEnabled: instagramMainAccountRestreamingConfiguration?.enabled || false,
      instagramMainAccountRestreamingKey: instagramMainAccountRestreamingConfiguration?.key || '',
      instagramMainAccountRestreamingUrl: instagramMainAccountRestreamingConfiguration?.url || '',
      instagramSecondAccountRestreamingEnabled: instagramSecondAccountRestreamingConfiguration?.enabled || false,
      instagramSecondAccountRestreamingKey: instagramSecondAccountRestreamingConfiguration?.key || '',
      instagramSecondAccountRestreamingUrl: instagramSecondAccountRestreamingConfiguration?.url || '',
      otherRTMPSourceRestreamingEnabled: otherRTMPRestreamingConfiguration?.enabled || false,
      otherRTMPSourceRestreamingKey: otherRTMPRestreamingConfiguration?.key || '',
      otherRTMPSourceRestreamingUrl: otherRTMPRestreamingConfiguration?.url || '',
      tiktokMainAccountRestreamingEnabled: tiktokMainAccountRestreamingConfiguration?.enabled || false,
      tiktokMainAccountRestreamingKey: tiktokMainAccountRestreamingConfiguration?.key || '',
      tiktokMainAccountRestreamingUrl: tiktokMainAccountRestreamingConfiguration?.url || '',
      tiktokSecondAccountRestreamingEnabled: tiktokSecondAccountRestreamingConfiguration?.enabled || false,
      tiktokSecondAccountRestreamingKey: tiktokSecondAccountRestreamingConfiguration?.key || '',
      tiktokSecondAccountRestreamingUrl: tiktokSecondAccountRestreamingConfiguration?.url || '',
      twitchMainAccountRestreamingEnabled: twitchMainAccountRestreamingConfiguration?.enabled || false,
      twitchMainAccountRestreamingKey: twitchMainAccountRestreamingConfiguration?.key || '',
      twitchMainAccountRestreamingUrl: twitchMainAccountRestreamingConfiguration?.url || '',
      twitchSecondAccountRestreamingEnabled: twitchSecondAccountRestreamingConfiguration?.enabled || false,
      twitchSecondAccountRestreamingKey: twitchSecondAccountRestreamingConfiguration?.key || '',
      twitchSecondAccountRestreamingUrl: twitchSecondAccountRestreamingConfiguration?.url || '',
      youtubeRestreamingConfiguration: event.youtubeRestreamingConfiguration || null,
    };
  };

  const { t } = useTranslation(['events', 'common']);
  const { register, handleSubmit, setValue, formState, reset, watch } = useForm<MultistreamFormInputs>({
    defaultValues: defaultValues(event),
  });

  const { errors, isDirty } = formState;

  const {
    facebookRestreamingConfiguration,
    instagramMainAccountRestreamingEnabled,
    instagramMainAccountRestreamingKey,
    instagramMainAccountRestreamingUrl,
    instagramSecondAccountRestreamingEnabled,
    otherRTMPSourceRestreamingEnabled,
    tiktokMainAccountRestreamingEnabled,
    tiktokMainAccountRestreamingKey,
    tiktokMainAccountRestreamingUrl,
    tiktokSecondAccountRestreamingEnabled,
    twitchMainAccountRestreamingEnabled,
    twitchMainAccountRestreamingKey,
    twitchMainAccountRestreamingUrl,
    twitchSecondAccountRestreamingEnabled,
    youtubeRestreamingConfiguration,
  } = watch();

  const hasInstagramMainAccountConfiguration =
    !!instagramMainAccountRestreamingKey && !!instagramMainAccountRestreamingUrl;

  const hasTiktokMainAccountConfiguration = !!tiktokMainAccountRestreamingKey && !!tiktokMainAccountRestreamingUrl;

  const hasTwitchMainAccountConfiguration = !!twitchMainAccountRestreamingKey && !!twitchMainAccountRestreamingUrl;

  useEffect(() => {
    if (isDirty) handleSubmit(onSubmit)();
  }, [facebookRestreamingConfiguration, youtubeRestreamingConfiguration]);

  useEffect(() => {
    resetForm();
  }, [event, reset]);

  const resetForm = () => {
    reset(defaultValues(event));
  };

  const onCancel = () => {
    resetForm();
  };

  const renderActionBar = () => (
    <StickyActionBarWrapper inProgress={submissionInProgress}>
      <MultistreamActionBar buttonsDisabled={submissionInProgress} isFormDirty={isDirty} onCancel={onCancel} />
    </StickyActionBarWrapper>
  );

  const onSubmit = (data: MultistreamFormInputs) => {
    const payload: UpdateMultistreamInputs = {
      facebookRestreamingConfiguration: data.facebookRestreamingConfiguration,
      restreamingConfigurations: [],
      youtubeRestreamingConfiguration: data.youtubeRestreamingConfiguration,
    };

    // Instagram main account
    if (data.instagramMainAccountRestreamingEnabled) {
      payload.restreamingConfigurations.push({
        enabled: data.instagramMainAccountRestreamingEnabled,
        expiresAt: addHours(new Date(), 5),
        key: data.instagramMainAccountRestreamingKey,
        type: V1.api.RestreamingPlatformSchema.enum.Instagram,
        url: data.instagramMainAccountRestreamingUrl,
      });

      // Instagram second account
      if (data.instagramSecondAccountRestreamingEnabled) {
        payload.restreamingConfigurations.push({
          enabled: data.instagramSecondAccountRestreamingEnabled,
          expiresAt: addHours(new Date(), 5),
          key: data.instagramSecondAccountRestreamingKey,
          type: V1.api.RestreamingPlatformSchema.enum.Instagram,
          url: data.instagramSecondAccountRestreamingUrl,
        });
      }
    }

    // TikTok main account
    if (data.tiktokMainAccountRestreamingEnabled) {
      payload.restreamingConfigurations.push({
        enabled: data.tiktokMainAccountRestreamingEnabled,
        expiresAt: null,
        key: data.tiktokMainAccountRestreamingKey,
        type: V1.api.RestreamingPlatformSchema.enum.TikTok,
        url: data.tiktokMainAccountRestreamingUrl,
      });

      // TikTok second account
      if (data.tiktokSecondAccountRestreamingEnabled) {
        payload.restreamingConfigurations.push({
          enabled: data.tiktokSecondAccountRestreamingEnabled,
          expiresAt: null,
          key: data.tiktokSecondAccountRestreamingKey,
          type: V1.api.RestreamingPlatformSchema.enum.TikTok,
          url: data.tiktokSecondAccountRestreamingUrl,
        });
      }
    }

    // Twitch main account
    if (data.twitchMainAccountRestreamingEnabled) {
      payload.restreamingConfigurations.push({
        enabled: data.twitchMainAccountRestreamingEnabled,
        expiresAt: null,
        key: data.twitchMainAccountRestreamingKey,
        type: V1.api.RestreamingPlatformSchema.enum.Twitch,
        url: data.twitchMainAccountRestreamingUrl,
      });

      // Twitch second account
      if (data.twitchSecondAccountRestreamingEnabled) {
        payload.restreamingConfigurations.push({
          enabled: data.twitchSecondAccountRestreamingEnabled,
          expiresAt: null,
          key: data.twitchSecondAccountRestreamingKey,
          type: V1.api.RestreamingPlatformSchema.enum.Twitch,
          url: data.twitchSecondAccountRestreamingUrl,
        });
      }
    }

    // Other RTMP
    if (data.otherRTMPSourceRestreamingEnabled) {
      payload.restreamingConfigurations.push({
        enabled: data.otherRTMPSourceRestreamingEnabled,
        expiresAt: null,
        key: data.otherRTMPSourceRestreamingKey,
        type: V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source,
        url: data.otherRTMPSourceRestreamingUrl,
      });
    }

    onUpdateEvent(payload);
  };

  const fieldRequiredIfInstagramMainAccountRestreamingEnabledValidator = (value: string) => {
    if (value === '' && instagramMainAccountRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: instagramMainAccountRestreamingKeyInputRef, ...instagramMainAccountRestreamingKeyInputProps } = register(
    'instagramMainAccountRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfInstagramMainAccountRestreamingEnabledValidator,
    },
  );

  const { ref: instagramMainAccountRestreamingUrlInputRef, ...instagramMainAccountRestreamingUrlInputProps } = register(
    'instagramMainAccountRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfInstagramMainAccountRestreamingEnabledValidator,
    },
  );

  const fieldRequiredIfInstagramSecondAccountRestreamingEnabledValidator = (value: string) => {
    if (value === '' && instagramSecondAccountRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: instagramSecondAccountRestreamingKeyInputRef, ...instagramSecondAccountRestreamingKeyInputProps } =
    register('instagramSecondAccountRestreamingKey', {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfInstagramSecondAccountRestreamingEnabledValidator,
    });

  const { ref: instagramSecondAccountRestreamingUrlInputRef, ...instagramSecondAccountRestreamingUrlInputProps } =
    register('instagramSecondAccountRestreamingUrl', {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfInstagramSecondAccountRestreamingEnabledValidator,
    });

  const fieldRequiredIfOtherRTMPSourceRestreamingEnabledValidator = (value: string) => {
    if (value === '' && otherRTMPSourceRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: otherRTMPSourceRestreamingKeyInputRef, ...otherRTMPSourceRestreamingKeyInputProps } = register(
    'otherRTMPSourceRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfOtherRTMPSourceRestreamingEnabledValidator,
    },
  );

  const { ref: otherRTMPSourceRestreamingUrlInputRef, ...otherRTMPSourceRestreamingUrlInputProps } = register(
    'otherRTMPSourceRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfOtherRTMPSourceRestreamingEnabledValidator,
    },
  );

  const fieldRequiredIfTiktokMainAccountRestreamingEnabledValidator = (value: string) => {
    if (value === '' && tiktokMainAccountRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: tiktokMainAccountRestreamingKeyInputRef, ...tiktokMainAccountRestreamingKeyInputProps } = register(
    'tiktokMainAccountRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTiktokMainAccountRestreamingEnabledValidator,
    },
  );

  const { ref: tiktokMainAccountRestreamingUrlInputRef, ...tiktokMainAccountRestreamingUrlInputProps } = register(
    'tiktokMainAccountRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTiktokMainAccountRestreamingEnabledValidator,
    },
  );

  const fieldRequiredIfTiktokSecondAccountRestreamingEnabledValidator = (value: string) => {
    if (value === '' && tiktokSecondAccountRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: tiktokSecondAccountRestreamingKeyInputRef, ...tiktokSecondAccountRestreamingKeyInputProps } = register(
    'tiktokSecondAccountRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTiktokSecondAccountRestreamingEnabledValidator,
    },
  );

  const { ref: tiktokSecondAccountRestreamingUrlInputRef, ...tiktokSecondAccountRestreamingUrlInputProps } = register(
    'tiktokSecondAccountRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTiktokSecondAccountRestreamingEnabledValidator,
    },
  );

  const fieldRequiredIfTwitchMainAccountRestreamingEnabledValidator = (value: string) => {
    if (value === '' && twitchMainAccountRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: twitchMainAccountRestreamingKeyInputRef, ...twitchMainAccountRestreamingKeyInputProps } = register(
    'twitchMainAccountRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTwitchMainAccountRestreamingEnabledValidator,
    },
  );

  const { ref: twitchMainAccountRestreamingUrlInputRef, ...twitchMainAccountRestreamingUrlInputProps } = register(
    'twitchMainAccountRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTwitchMainAccountRestreamingEnabledValidator,
    },
  );

  const fieldRequiredIfTwitchSecondAccountRestreamingEnabledValidator = (value: string) => {
    if (value === '' && twitchSecondAccountRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: twitchSecondAccountRestreamingKeyInputRef, ...twitchSecondAccountRestreamingKeyInputProps } = register(
    'twitchSecondAccountRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTwitchSecondAccountRestreamingEnabledValidator,
    },
  );

  const { ref: twitchSecondAccountRestreamingUrlInputRef, ...twitchSecondAccountRestreamingUrlInputProps } = register(
    'twitchSecondAccountRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfTwitchSecondAccountRestreamingEnabledValidator,
    },
  );

  const handleChangeRestreamingEnabled =
    (label: keyof MultistreamFormInputs) =>
    (value: boolean | (CBOFacebookRestreamingConfiguration | CBOYoutubeRestreamingConfiguration | null)) => {
      value ? onAddRestreaming() : onRemoveRestreaming();
      setValue(label, value, { shouldDirty: true });
    };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Root id="event-form">
        <Grid container flex={1} justifyContent="center" p={3}>
          <Grid item lg={8} md={10} xs={12}>
            <Container boxShadow={3} disabled={!canEnableMultistream} py={2}>
              <InstagramRestreamingSection
                canEnableMultistream={canEnableMultistream}
                hasMainAccountConfiguration={hasInstagramMainAccountConfiguration}
                isMaximumRestreamingsReached={isMaximumRestreamingsReached}
                keyError={{
                  mainAccount: errors?.instagramMainAccountRestreamingKey?.message,
                  secondAccount: errors?.instagramSecondAccountRestreamingKey?.message,
                }}
                mainAccountExpiresAt={instagramMainAccountRestreamingConfiguration?.expiresAt ?? null}
                mainAccountRestreamingEnabledWatch={instagramMainAccountRestreamingEnabled}
                mainAccountRestreamingKeyInputProps={instagramMainAccountRestreamingKeyInputProps}
                mainAccountRestreamingKeyInputRef={instagramMainAccountRestreamingKeyInputRef}
                mainAccountRestreamingUrlInputProps={instagramMainAccountRestreamingUrlInputProps}
                mainAccountRestreamingUrlInputRef={instagramMainAccountRestreamingUrlInputRef}
                onChangeMainAccountRestreamingEnabled={handleChangeRestreamingEnabled(
                  'instagramMainAccountRestreamingEnabled',
                )}
                onChangeSecondAccountRestreamingEnabled={handleChangeRestreamingEnabled(
                  'instagramSecondAccountRestreamingEnabled',
                )}
                readonly={readonly}
                secondAccountExpiresAt={instagramSecondAccountRestreamingConfiguration?.expiresAt ?? null}
                secondAccountRestreamingEnabledWatch={instagramSecondAccountRestreamingEnabled}
                secondAccountRestreamingKeyInputProps={instagramSecondAccountRestreamingKeyInputProps}
                secondAccountRestreamingKeyInputRef={instagramSecondAccountRestreamingKeyInputRef}
                secondAccountRestreamingUrlInputProps={instagramSecondAccountRestreamingUrlInputProps}
                secondAccountRestreamingUrlInputRef={instagramSecondAccountRestreamingUrlInputRef}
                submissionInProgress={submissionInProgress}
                type={V1.api.RestreamingPlatformSchema.enum.Instagram}
                urlError={{
                  mainAccount: errors?.instagramMainAccountRestreamingUrl?.message,
                  secondAccount: errors?.instagramSecondAccountRestreamingUrl?.message,
                }}
              />
              <Divider />
              <TiktokRestreamingSection
                canEnableMultistream={canEnableMultistream}
                hasMainAccountConfiguration={hasTiktokMainAccountConfiguration}
                isMaximumRestreamingsReached={isMaximumRestreamingsReached}
                keyError={{
                  mainAccount: errors?.tiktokMainAccountRestreamingKey?.message,
                  secondAccount: errors?.tiktokSecondAccountRestreamingKey?.message,
                }}
                mainAccountExpiresAt={tiktokMainAccountRestreamingConfiguration?.expiresAt ?? null}
                mainAccountRestreamingEnabledWatch={tiktokMainAccountRestreamingEnabled}
                mainAccountRestreamingKeyInputProps={tiktokMainAccountRestreamingKeyInputProps}
                mainAccountRestreamingKeyInputRef={tiktokMainAccountRestreamingKeyInputRef}
                mainAccountRestreamingUrlInputProps={tiktokMainAccountRestreamingUrlInputProps}
                mainAccountRestreamingUrlInputRef={tiktokMainAccountRestreamingUrlInputRef}
                onChangeMainAccountRestreamingEnabled={handleChangeRestreamingEnabled(
                  'tiktokMainAccountRestreamingEnabled',
                )}
                onChangeSecondAccountRestreamingEnabled={handleChangeRestreamingEnabled(
                  'tiktokSecondAccountRestreamingEnabled',
                )}
                readonly={readonly}
                secondAccountExpiresAt={tiktokSecondAccountRestreamingConfiguration?.expiresAt ?? null}
                secondAccountRestreamingEnabledWatch={tiktokSecondAccountRestreamingEnabled}
                secondAccountRestreamingKeyInputProps={tiktokSecondAccountRestreamingKeyInputProps}
                secondAccountRestreamingKeyInputRef={tiktokSecondAccountRestreamingKeyInputRef}
                secondAccountRestreamingUrlInputProps={tiktokSecondAccountRestreamingUrlInputProps}
                secondAccountRestreamingUrlInputRef={tiktokSecondAccountRestreamingUrlInputRef}
                submissionInProgress={submissionInProgress}
                type={V1.api.RestreamingPlatformSchema.enum.TikTok}
                urlError={{
                  mainAccount: errors?.tiktokMainAccountRestreamingUrl?.message,
                  secondAccount: errors?.tiktokSecondAccountRestreamingUrl?.message,
                }}
              />
              <Divider />
              <TwitchRestreamingSection
                canEnableMultistream={canEnableMultistream}
                hasMainAccountConfiguration={hasTwitchMainAccountConfiguration}
                isMaximumRestreamingsReached={isMaximumRestreamingsReached}
                keyError={{
                  mainAccount: errors?.twitchMainAccountRestreamingKey?.message,
                  secondAccount: errors?.twitchSecondAccountRestreamingKey?.message,
                }}
                mainAccountExpiresAt={twitchMainAccountRestreamingConfiguration?.expiresAt ?? null}
                mainAccountRestreamingEnabledWatch={twitchMainAccountRestreamingEnabled}
                mainAccountRestreamingKeyInputProps={twitchMainAccountRestreamingKeyInputProps}
                mainAccountRestreamingKeyInputRef={twitchMainAccountRestreamingKeyInputRef}
                mainAccountRestreamingUrlInputProps={twitchMainAccountRestreamingUrlInputProps}
                mainAccountRestreamingUrlInputRef={twitchMainAccountRestreamingUrlInputRef}
                onChangeMainAccountRestreamingEnabled={handleChangeRestreamingEnabled(
                  'twitchMainAccountRestreamingEnabled',
                )}
                onChangeSecondAccountRestreamingEnabled={handleChangeRestreamingEnabled(
                  'twitchSecondAccountRestreamingEnabled',
                )}
                readonly={readonly}
                secondAccountExpiresAt={twitchSecondAccountRestreamingConfiguration?.expiresAt ?? null}
                secondAccountRestreamingEnabledWatch={twitchSecondAccountRestreamingEnabled}
                secondAccountRestreamingKeyInputProps={twitchSecondAccountRestreamingKeyInputProps}
                secondAccountRestreamingKeyInputRef={twitchSecondAccountRestreamingKeyInputRef}
                secondAccountRestreamingUrlInputProps={twitchSecondAccountRestreamingUrlInputProps}
                secondAccountRestreamingUrlInputRef={twitchSecondAccountRestreamingUrlInputRef}
                submissionInProgress={submissionInProgress}
                type={V1.api.RestreamingPlatformSchema.enum.Twitch}
                urlError={{
                  mainAccount: errors?.twitchMainAccountRestreamingUrl?.message,
                  secondAccount: errors?.twitchSecondAccountRestreamingUrl?.message,
                }}
              />
              <Divider />
              <OtherRTMPSourceRestreamingSection
                canEnableMultistream={canEnableMultistream}
                isMaximumRestreamingsReached={isMaximumRestreamingsReached}
                keyError={{
                  mainAccount: errors?.otherRTMPSourceRestreamingKey?.message,
                }}
                mainAccountExpiresAt={otherRTMPRestreamingConfiguration?.expiresAt ?? null}
                mainAccountRestreamingEnabledWatch={otherRTMPSourceRestreamingEnabled}
                mainAccountRestreamingKeyInputProps={otherRTMPSourceRestreamingKeyInputProps}
                mainAccountRestreamingKeyInputRef={otherRTMPSourceRestreamingKeyInputRef}
                mainAccountRestreamingUrlInputProps={otherRTMPSourceRestreamingUrlInputProps}
                mainAccountRestreamingUrlInputRef={otherRTMPSourceRestreamingUrlInputRef}
                onChangeMainAccountRestreamingEnabled={handleChangeRestreamingEnabled(
                  'otherRTMPSourceRestreamingEnabled',
                )}
                readonly={readonly}
                submissionInProgress={submissionInProgress}
                type={V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source}
                urlError={{
                  mainAccount: errors?.otherRTMPSourceRestreamingUrl?.message,
                }}
              />
              <Divider />
              <FacebookRestreamingSection
                {...facebookIntegrationProps}
                canEnableMultistream={canEnableMultistream}
                configuredAccount={facebookRestreamingConfiguration}
                isMaximumRestreamingsReached={isMaximumRestreamingsReached}
                onChange={handleChangeRestreamingEnabled('facebookRestreamingConfiguration')}
                readonly={readonly}
                submissionInProgress={submissionInProgress}
              />
              <Divider />
              <YoutubeRestreamingSection
                {...youtubeIntegrationProps}
                canEnableMultistream={canEnableMultistream}
                configuredAccount={youtubeRestreamingConfiguration}
                isMaximumRestreamingsReached={isMaximumRestreamingsReached}
                onChange={handleChangeRestreamingEnabled('youtubeRestreamingConfiguration')}
                readonly={readonly}
                submissionInProgress={submissionInProgress}
              />
            </Container>
          </Grid>
        </Grid>
        {!readonly && renderActionBar()}
      </Root>
    </form>
  );
}
