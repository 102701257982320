import { Box, Button, Typography, alpha, styled } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOFacebookPage, CBOFacebookUser } from '../../../../../../core/domain/CBOFacebookAccount';
import FacebookAccountCard from '../../../../atoms/event/FacebookAccountCard';
import FacebookRestreamingRulesWarning from '../../../../atoms/event/FacebookRestreamingRulesWarning';

const AccountsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  justifyContent: 'center',
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));

interface SelectFacebookAccountProps {
  areButtonsDisabled: boolean;
  onDisconnect: () => void;
  onSelectPage: (id: string) => void;
  onSelectUser: () => void;
  pages: CBOFacebookPage[];
  user: CBOFacebookUser;
}

export default function SelectFacebookAccount({
  areButtonsDisabled,
  onDisconnect,
  onSelectUser,
  onSelectPage,
  pages,
  user,
}: SelectFacebookAccountProps) {
  const { t } = useTranslation('events', { keyPrefix: 'FacebookRestreaming' });
  const [selectedAccountId, setSelectedAccountId] = useState<string>(user.id);

  const handleConfirmSelection = () => {
    selectedAccountId === user.id ? onSelectUser() : onSelectPage(selectedAccountId);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize=".9em">{t('SelectAccountForRestreaming')}</Typography>
      <FacebookRestreamingRulesWarning />
      <AccountsContainer>
        <FacebookAccountCard
          account={user}
          onClick={() => setSelectedAccountId(user.id)}
          selectable
          selected={selectedAccountId === user.id}
        />
        {pages.map((page) => (
          <FacebookAccountCard
            account={page}
            key={page.id}
            onClick={() => setSelectedAccountId(page.id)}
            selectable
            selected={selectedAccountId === page.id}
          />
        ))}
      </AccountsContainer>
      <ButtonsContainer>
        <Button
          disabled={areButtonsDisabled}
          onClick={onDisconnect}
          sx={{
            borderColor: (theme) => alpha(theme.palette.socials.facebook, 0.8),
            color: (theme) => theme.palette.socials.facebook,
            textTransform: 'initial',
          }}
          variant="outlined"
        >
          {t('common:Disconnect')}
        </Button>
        <Button
          disabled={areButtonsDisabled}
          onClick={handleConfirmSelection}
          sx={{ backgroundColor: (theme) => theme.palette.socials.facebook, textTransform: 'initial' }}
          variant="contained"
        >
          {t('SelectAccount')}
        </Button>
      </ButtonsContainer>
    </Box>
  );
}
