import { SettingsInputAntenna } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RTMPRestreamingSection, { RestreamingProps, RestreamingSectionProps } from './RTMPRestreamingSection';

const OtherRTMPSourceRestreamingSection: FC<RestreamingSectionProps> = ({
  canEnableMultistream,
  keyError,
  urlError,
  ...props
}) => {
  const { t } = useTranslation(['events', 'common']);

  const otherRTMPSourceRestreamingProps: RestreamingProps = useMemo(
    () => ({
      id: 'other-rtmp-source',
      introductionText: t('ShortRTMPKeyValidityWarning'),
      keyError,
      logo: (
        <Avatar
          sx={{
            bgcolor: (theme) => (canEnableMultistream ? theme.palette.primary.main : theme.palette.grey[500]),
          }}
        >
          <SettingsInputAntenna />
        </Avatar>
      ),
      secondAccount: {
        active: false,
      },
      title: t('common:Socials.OtherRTMPSource'),
      urlError,
    }),
    [canEnableMultistream, keyError, t, urlError],
  );

  return (
    <RTMPRestreamingSection
      {...props}
      canEnableMultistream={canEnableMultistream}
      restreamingProps={otherRTMPSourceRestreamingProps}
    />
  );
};

export default OtherRTMPSourceRestreamingSection;
