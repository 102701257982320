import { Box, LinearProgress, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';

import { eventToUserJourneyData } from '../../../../utils/event-to-user-journey-data';
import StyledDialog from '../../../templates/dialog/StyledDialog';
import AddProductsToEventDialog from '../event/event-details/AddProductsToEventDialog';
import ProductList from '../event/event-details/ProductList';
import { createOnboardingAddProductsViewModel } from './OnboardingAddProducts.viewmodel';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
});

const tourStep: Step = {
  content: '',
  disableBeacon: true,
  disableCloseOnEsc: true,
  disableOverlayClose: true,
  floaterProps: {
    styles: {
      floater: {
        display: 'none',
      },
    },
  },
  spotlightClicks: true,
  target: `.onboarding-tour-highlighted`,
};

const OnboardingAddProducts = () => {
  const { t } = useTranslation('onboarding', { keyPrefix: 'Steps.AddProducts' });
  const theme = useTheme();
  const viewModel = useSelector(createOnboardingAddProductsViewModel({ dispatch: useDispatch() }));

  const { event, trackUserJourneyEvent, tenantCurrency, tenantId, tenantName } = viewModel;

  const [isAddCatalogProductsDialogOpen, setIsAddCatalogProductsDialogOpen] = useState(false);
  const [isPresentationModalOpen, setIsPresentationModalOpen] = useState(false);

  useEffect(() => {
    if (event)
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': tenantId,
          'Tenant name': tenantName,
        },
        name: 'Onboarding - Add products',
      });
  }, [event?.id]);

  useEffect(() => {
    if (event?.products.length === 0) {
      setIsPresentationModalOpen(true);
    }
  }, [event?.id]);

  const handleOpenAddCatalogProductsModal = () => {
    setIsAddCatalogProductsDialogOpen(true);
    setIsPresentationModalOpen(false);
  };

  return (
    <Container>
      <Joyride
        run={isPresentationModalOpen}
        steps={[tourStep]}
        styles={{ overlay: { zIndex: theme.zIndex.drawer + 2 } }}
      />
      <StyledDialog
        allowClicksThroughBackdrop
        content={t('PresentationModal.Content')}
        disableBackdropClose
        hiddenBackdrop
        open={isPresentationModalOpen}
        title={t('PresentationModal.Title')}
      />
      {event ? (
        <>
          <AddProductsToEventDialog
            event={event}
            isOpen={isAddCatalogProductsDialogOpen}
            onClose={() => setIsAddCatalogProductsDialogOpen(false)}
          />
          <ProductList
            currency={tenantCurrency}
            eventId={event.id}
            hasCatalogProducts
            isOnboarding
            onAddProduct={handleOpenAddCatalogProductsModal}
            products={event.products}
            productsLoading={false}
            sx={{
              height: `100%`,
            }}
          />
        </>
      ) : (
        <LinearProgress />
      )}
    </Container>
  );
};

export default OnboardingAddProducts;
