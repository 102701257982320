import { Cms, TenantId } from '@bellepoque/api-contracts';

import { CBOCurrency } from '../../domain/CBOCurrency';
import { CBOTenant } from '../../domain/CBOTenant';
import { CommandResult, INITIAL_COMMAND_RESULT, INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface TenantsCommandsQueriesState {
  renewApiKey: CommandResult;
  tenantFetching: QueryResult;
  tenantProductsFetching: QueryResult;
  tenantsFetching: QueryResult;
  updateAlias: CommandResult;
  updateCurrency: CommandResult;
  updateIntegrations: CommandResult;
}

export interface TenantsState extends TenantsCommandsQueriesState {
  currentTenant: CBOTenant;
  currentTenantId: TenantId;
  tenants: CBOTenant[];
}

export const DEFAULT_CURRENCY: CBOCurrency = { code: 'EUR', decimalDigits: 2, id: '', symbol: '€' };

export const INITIAL_TENANTS_STATE: TenantsState = {
  currentTenant: {
    activeSubscriptionId: 'live_access',
    alias: '',
    apiKey: '',
    cms: Cms.Unknown,
    createdAt: new Date(),
    currency: DEFAULT_CURRENCY,
    hasCatalogProducts: true,
    id: '',
    integrations: {
      klaviyo: {
        apiKey: null,
        listId: null,
      },
      tapcart: {
        screenId: null,
      },
    },
    name: '',
  },
  currentTenantId: '',
  renewApiKey: INITIAL_COMMAND_RESULT,
  tenantFetching: INITIAL_QUERY_RESULT,
  tenantProductsFetching: INITIAL_QUERY_RESULT,
  tenants: [],
  tenantsFetching: INITIAL_QUERY_RESULT,
  updateAlias: INITIAL_COMMAND_RESULT,
  updateCurrency: INITIAL_COMMAND_RESULT,
  updateIntegrations: INITIAL_COMMAND_RESULT,
};
