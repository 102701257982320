import { V1 } from '@bellepoque/api-contracts';
import { Add, Close, Visibility, VisibilityOff, WarningRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Switch,
  TextField,
  Typography,
  accordionClasses,
  accordionSummaryClasses,
  styled,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputTooltip from '../../../../atoms/inputs/InputTooltip';

const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'initial',
});

const HelpMessageContainer = styled(Box)({
  display: 'block',
  justifyContent: 'flex-start',
  width: '100%',
});

const AccordionRoot = styled(Accordion)(({ theme }) => ({
  '&::before': {
    display: 'none',
  },
  backgroundColor: 'transparent',

  [`&.${accordionClasses.expanded}`]: {
    margin: 0,

    [`& .${accordionSummaryClasses.content}`]: {
      margin: '12px 0',
    },
  },

  [`& .${accordionSummaryClasses.root}`]: {
    padding: `0 ${theme.spacing(3)}`,
  },
}));

export type RestreamingErrorsProps = {
  keyError: {
    mainAccount?: string;
    secondAccount?: string;
  };
  urlError: {
    mainAccount?: string;
    secondAccount?: string;
  };
};

type RestreamingSecondAccountDisabled = { active: false };

type RestreamingSecondAccountEnabled = { active: true; introductionText?: string };

type RestreamingSecondAccount = RestreamingSecondAccountDisabled | RestreamingSecondAccountEnabled;

export type RestreamingProps = RestreamingErrorsProps & {
  helpLink?: string;
  id: string;
  introductionText?: string;
  keyTooltip?: string;
  logo?: JSX.Element;
  secondAccount: RestreamingSecondAccount;
  title: string;
  urlTooltip?: string;
};

export type RTMPRestreamingSectionProps = {
  canEnableMultistream: boolean;
  hasMainAccountConfiguration?: boolean;
  introductionText?: string;
  isMaximumRestreamingsReached: boolean;
  mainAccountExpiresAt: Date | null;
  mainAccountRestreamingEnabledWatch: boolean;
  mainAccountRestreamingKeyInputProps: any;
  mainAccountRestreamingKeyInputRef: React.Ref<any>;
  mainAccountRestreamingUrlInputProps: any;
  mainAccountRestreamingUrlInputRef: React.Ref<any>;
  onChangeMainAccountRestreamingEnabled: (value: boolean) => void;
  onChangeSecondAccountRestreamingEnabled?: (value: boolean) => void;
  readonly: boolean;
  restreamingProps: RestreamingProps;
  secondAccountExpiresAt?: Date | null;
  secondAccountRestreamingEnabledWatch?: boolean;
  secondAccountRestreamingKeyInputProps?: any;
  secondAccountRestreamingKeyInputRef?: React.Ref<any>;
  secondAccountRestreamingUrlInputProps?: any;
  secondAccountRestreamingUrlInputRef?: React.Ref<any>;
  submissionInProgress: boolean;
  type:
    | typeof V1.api.RestreamingPlatformSchema.enum.Instagram
    | typeof V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source
    | typeof V1.api.RestreamingPlatformSchema.enum.TikTok
    | typeof V1.api.RestreamingPlatformSchema.enum.Twitch;
};

export type RestreamingSectionProps = Omit<RTMPRestreamingSectionProps, 'restreamingProps'> & RestreamingErrorsProps;

const RTMPRestreamingSection: FC<RTMPRestreamingSectionProps> = ({
  canEnableMultistream,
  hasMainAccountConfiguration,
  isMaximumRestreamingsReached,
  mainAccountExpiresAt,
  mainAccountRestreamingEnabledWatch,
  mainAccountRestreamingKeyInputProps,
  mainAccountRestreamingKeyInputRef,
  mainAccountRestreamingUrlInputProps,
  mainAccountRestreamingUrlInputRef,
  onChangeMainAccountRestreamingEnabled,
  onChangeSecondAccountRestreamingEnabled,
  readonly,
  restreamingProps,
  secondAccountExpiresAt,
  secondAccountRestreamingEnabledWatch,
  secondAccountRestreamingKeyInputProps,
  secondAccountRestreamingKeyInputRef,
  secondAccountRestreamingUrlInputProps,
  secondAccountRestreamingUrlInputRef,
  submissionInProgress,
  type,
}) => {
  const { t } = useTranslation(['events', 'common']);
  const [isStreamKeyShown, setIsStreamKeyShown] = useState(false);
  const mainAccountHasExpired =
    mainAccountRestreamingEnabledWatch && mainAccountExpiresAt && mainAccountExpiresAt < new Date();

  const secondAccountHasExpired =
    secondAccountRestreamingEnabledWatch && secondAccountExpiresAt && secondAccountExpiresAt < new Date();

  const [isSecondAccountSectionVisible, displaySecondAccountSection] = useState(secondAccountRestreamingEnabledWatch);

  return (
    <AccordionRoot disabled={readonly} elevation={0} expanded={mainAccountRestreamingEnabledWatch}>
      <AccordionSummary sx={{ cursor: 'text' }}>
        <Box alignItems="center" display="flex" flex={1} justifyContent="space-between">
          <Box alignItems="center" display="flex" gap={2}>
            {restreamingProps.logo}
            <TitleContainer>
              <Box alignItems="center" display="flex" gap="8px">
                <Typography color={readonly ? 'gray' : 'black'} fontSize="1em" fontWeight="bold" variant="titleFont">
                  {restreamingProps.title}
                </Typography>
              </Box>

              {!!restreamingProps.helpLink && (
                <HelpMessageContainer>
                  <Typography fontSize=".8em">
                    {restreamingProps.secondAccount.active &&
                      t('RestreamingHelp', {
                        platform: restreamingProps.title,
                      })}
                    <Link href={restreamingProps.helpLink} target="_blank">
                      {t('RestreamingHelpLinkText')}
                    </Link>
                  </Typography>
                </HelpMessageContainer>
              )}
            </TitleContainer>
          </Box>
          <Box alignItems="center" display="flex">
            <Switch
              checked={mainAccountRestreamingEnabledWatch}
              disabled={
                (isMaximumRestreamingsReached && !mainAccountRestreamingEnabledWatch) ||
                !canEnableMultistream ||
                submissionInProgress ||
                readonly
              }
              onChange={(_event, val) => {
                onChangeMainAccountRestreamingEnabled(val);

                if (secondAccountRestreamingEnabledWatch) {
                  onChangeSecondAccountRestreamingEnabled?.(val);
                  displaySecondAccountSection(val);
                }
              }}
            />
            {mainAccountHasExpired && <WarningRounded color="error" cursor="initial" />}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {!!restreamingProps.introductionText && (
          <Typography fontSize=".8em" whiteSpace="pre-line">
            {restreamingProps.introductionText}
          </Typography>
        )}
        <Grid item position="relative" xs={12}>
          <TextField
            disabled={submissionInProgress || readonly}
            error={!!restreamingProps.urlError.mainAccount}
            fullWidth
            helperText={restreamingProps.urlError.mainAccount}
            id={`${restreamingProps.id}-restreaming-url`}
            inputRef={mainAccountRestreamingUrlInputRef}
            label={
              restreamingProps.secondAccount.active ? t('RestreamingStreamUrl', { accountNumber: 1 }) : t('StreamUrl')
            }
            variant="filled"
            {...mainAccountRestreamingUrlInputProps}
          />
          {!!restreamingProps.urlTooltip && <InputTooltip content={restreamingProps.urlTooltip} />}
        </Grid>
        <Grid item position="relative" xs={12}>
          <TextField
            InputProps={{
              endAdornment: (
                <IconButton disabled={readonly} onClick={() => setIsStreamKeyShown(!isStreamKeyShown)}>
                  {isStreamKeyShown ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            disabled={submissionInProgress || readonly}
            error={!!restreamingProps.keyError.mainAccount}
            fullWidth
            helperText={restreamingProps.keyError.mainAccount}
            id={`${restreamingProps.id}-restreaming-key`}
            inputRef={mainAccountRestreamingKeyInputRef}
            label={
              restreamingProps.secondAccount.active ? t('RestreamingStreamKey', { accountNumber: 1 }) : t('StreamKey')
            }
            type={isStreamKeyShown ? 'text' : 'password'}
            variant="filled"
            {...mainAccountRestreamingKeyInputProps}
          />
          {mainAccountHasExpired && (
            <Typography color="error" fontSize=".8em" mt={1}>
              {t('RestreamingConfigurationExpired', { platform: type })}
            </Typography>
          )}
          {!!restreamingProps.keyTooltip && <InputTooltip content={restreamingProps.keyTooltip} />}
        </Grid>
      </AccordionDetails>

      {restreamingProps.secondAccount.active && (
        <>
          {!isSecondAccountSectionVisible ? (
            <Button
              disabled={!hasMainAccountConfiguration || isMaximumRestreamingsReached || readonly}
              onClick={() => {
                displaySecondAccountSection(true);
                onChangeSecondAccountRestreamingEnabled?.(true);
              }}
              startIcon={<Add />}
            >
              {t('AddSecondAccount')}
            </Button>
          ) : (
            <Button
              onClick={() => {
                displaySecondAccountSection(false);
                onChangeSecondAccountRestreamingEnabled?.(false);
              }}
              startIcon={<Close />}
            >
              {t('RemoveSecondAccount')}
            </Button>
          )}

          {isSecondAccountSectionVisible && (
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {!!restreamingProps.introductionText && (
                <Typography fontSize=".8em" whiteSpace="pre-line">
                  {restreamingProps.secondAccount.introductionText}
                </Typography>
              )}
              <Grid item position="relative" xs={12}>
                <TextField
                  disabled={submissionInProgress || readonly}
                  error={!!restreamingProps.urlError.secondAccount}
                  fullWidth
                  helperText={restreamingProps.urlError.secondAccount}
                  id={`${restreamingProps.id}-restreaming-url`}
                  inputRef={secondAccountRestreamingUrlInputRef}
                  label={
                    restreamingProps.secondAccount.active
                      ? t('RestreamingStreamUrl', { accountNumber: 2 })
                      : t('StreamUrl')
                  }
                  variant="filled"
                  {...secondAccountRestreamingUrlInputProps}
                />
                {!!restreamingProps.urlTooltip && <InputTooltip content={restreamingProps.urlTooltip} />}
              </Grid>
              <Grid item position="relative" xs={12}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <IconButton disabled={readonly} onClick={() => setIsStreamKeyShown(!isStreamKeyShown)}>
                        {isStreamKeyShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  disabled={submissionInProgress || readonly}
                  error={!!restreamingProps.keyError.secondAccount}
                  fullWidth
                  helperText={restreamingProps.keyError.secondAccount}
                  id={`${restreamingProps.id}-restreaming-key`}
                  inputRef={secondAccountRestreamingKeyInputRef}
                  label={
                    restreamingProps.secondAccount.active
                      ? t('RestreamingStreamKey', { accountNumber: 2 })
                      : t('StreamKey')
                  }
                  type={isStreamKeyShown ? 'text' : 'password'}
                  variant="filled"
                  {...secondAccountRestreamingKeyInputProps}
                />
                {secondAccountHasExpired && (
                  <Typography color="error" fontSize=".8em" mt={1}>
                    {t('RestreamingConfigurationExpired', { platform: type })}
                  </Typography>
                )}
                {!!restreamingProps.keyTooltip && <InputTooltip content={restreamingProps.keyTooltip} />}
              </Grid>
            </AccordionDetails>
          )}
        </>
      )}
    </AccordionRoot>
  );
};

export default RTMPRestreamingSection;
